import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../App";

export const PublicRoute = ({ children }) => {
  const { userLoggedIn, lastRoute } = useContext(UserContext);
  // console.log("public");

  if (userLoggedIn) {
    if (lastRoute) {
      return <Navigate to={`${lastRoute}`} />;
    }
    return <Navigate to="/dashboard" replace />;
  }
  return children;
};
export default PublicRoute;
