import { Toast, ToastContainer } from "react-bootstrap";
import "../assets/css/toast.css";

const ToastNotification = ({ setShow, show, content }) => {
  return (
    <div className="toast-div" style={{ width: "100%" }}>
      <ToastContainer
        className="p-3"
        style={{ width: "100%" }}
        position="bottom-center"
      >
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Body
            className="py-3"
            style={{ background: "#94dde9", fontSize: "18px" }}
          >
            {content}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};
export default ToastNotification;
