import { callAPI } from "../config/api-core";
import { procedureAPIMethods } from "../methods/procedure-api-method";

const procedureAPI = async () => {
  const response = await callAPI(
    procedureAPIMethods,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

export const procedure = () => procedureAPI();
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  procedure,
};
