import { callAPI } from "../config/api-core";
import {
  contactSupportAPIMethod,
  helpSupportAPIMethod,
  suggestFeatureAPIMethod,
} from "../methods/help-api-method";

const helpCenterAPI = async (request) => {
  const response = await callAPI(
    helpSupportAPIMethod,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

const contactSupportAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };

  const response = await callAPI(
    contactSupportAPIMethod,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

const suggestFeatureAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };

  const response = await callAPI(
    suggestFeatureAPIMethod,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

export const getHelpCenter = (request) => helpCenterAPI(request);
export const contactSupport = (request) => contactSupportAPI(request);
export const suggestFeature = (request) => suggestFeatureAPI(request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getHelpCenter,
  contactSupport,
  suggestFeature,
};
