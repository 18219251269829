import React, { useContext, useState } from "react";
import "../assets/css/newsidebar.css";
import { Outlet, NavLink } from "react-router-dom";
import helpIcon from "../assets/icons/help.svg";
import supportIcon from "../assets/icons/support.svg";
import suggestIcon from "../assets/icons/suggest.svg";
import termsIcon from "../assets/icons/terms.svg";
import policyIcon from "../assets/icons/policy.svg";
import privacyIcon from "../assets/icons/privacy.svg";

import colorHelpIcon from "../assets/icons/color-help.svg";
import colorSupportIcon from "../assets/icons/color-support.svg";
import colorSuggestIcon from "../assets/icons/color-suggest.svg";
import colorTermsIcon from "../assets/icons/color-terms.svg";
import colorPolicyIcon from "../assets/icons/color-policy.svg";
import colorPrivacyIcon from "../assets/icons/color-privacy.svg";
import referralIcon from "../assets/icons/referral.svg";
import referralActiveIcon from "../assets/icons/referral-active.svg";
import chatIcon from "../assets/icons/chat.svg";
import chatActiveIcon from "../assets/icons/chat-active.svg";
import { Badge } from "react-bootstrap";
import { UserContext } from "../App";

const NewSidebar = () => {
  const [helpDropdownOpen, setHelpDropdownOpen] = useState(false);
  const [legalDropdownOpen, setLegalDropdownOpen] = useState(false);

  const { reverseReferralsCount } = useContext(UserContext);

  // const toggleDropdown = (dropdownState, setDropdownState) => {
  //   setDropdownState(!dropdownState);
  // };

  const toggleHelpDropdown = () => {
    setHelpDropdownOpen(!helpDropdownOpen);
    setLegalDropdownOpen(false);
  };

  const toggleLegalDropdown = () => {
    setLegalDropdownOpen(!legalDropdownOpen);
    setHelpDropdownOpen(false);
  };

  const handleCloseDropdown = (setDropdownState) => {
    setDropdownState(false);
  };

  const dropdownItems = [
    {
      title: "Help",
      iconClass: "fa-question-circle",
      submenuItems: [
        {
          title: "Help Center",
          path: "/help-center",
          icon: helpIcon,
          activeIcon: colorHelpIcon,
        },
        {
          title: "Contact Support",
          path: "/contact-support",
          icon: supportIcon,
          activeIcon: colorSupportIcon,
        },
        {
          title: "Suggest a Feature",
          path: "/suggest-a-feature",
          icon: suggestIcon,
          activeIcon: colorSuggestIcon,
        },
      ],
      dropdownOpen: helpDropdownOpen,
      toggleDropdown: toggleHelpDropdown,
      // toggleDropdown: () =>
      //   toggleDropdown(helpDropdownOpen, setHelpDropdownOpen),
      handleCloseDropdown: () => handleCloseDropdown(setHelpDropdownOpen),
    },
    {
      title: "Legal",
      iconClass: "fa-gavel",
      submenuItems: [
        {
          title: "Terms of Service",
          path: "/terms-of-service",
          icon: termsIcon,
          activeIcon: colorTermsIcon,
        },
        {
          title: "Privacy Policy",
          path: "/privacy-policy",
          icon: policyIcon,
          activeIcon: colorPolicyIcon,
        },
        {
          title: "Privacy Settings",
          path: "/privacy-settings",
          icon: privacyIcon,
          activeIcon: colorPrivacyIcon,
        },
        {
          title: "Privacy Preferences",
          path: "/privacy-preferences",
          icon: privacyIcon,
          activeIcon: colorPrivacyIcon,
        },
      ],
      dropdownOpen: legalDropdownOpen,
      toggleDropdown: toggleLegalDropdown,
      // toggleDropdown: () =>
      //   toggleDropdown(legalDropdownOpen, setLegalDropdownOpen),
      handleCloseDropdown: () => handleCloseDropdown(setLegalDropdownOpen),
    },
  ];

  return (
    <>
      <div className="Newsidebar-div mv_sidebar">
        <ul className="navbar-nav">
          <li className="nav-item nav-list">
            <NavLink to="/dashboard" className="nav-linking">
              <i className="fa fa-house-chimney"></i>
              <span className="newheader-span">Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item nav-list">
            <NavLink to="/mypatient" className="nav-linking">
              <i className="fa-regular fa-user"></i>
              <span className="newheader-span">My Patients</span>
            </NavLink>
          </li>

          {/* <li className="nav-item nav-list">
            <NavLink to="/documents" className="nav-linking">
              <i className="fa fa-file"></i>
              <span className="newheader-span">Documents</span>
            </NavLink>
          </li>

          <li className="nav-item nav-list" style={{ position: "relative" }}>
            <NavLink to="/notification" className="nav-linking">
              <i className="fa-regular fa-bell"></i>
              <span className="newheader-span">Notifications</span>
            </NavLink>
          </li> */}
          <li className="nav-item nav-list" style={{ position: "relative" }}>
            <NavLink to="/reverse-referral" className="nav-linking">
              {/* <i className="fa-regular fa-bell"></i> */}

              <img
                src={referralIcon}
                alt="..."
                className="menu-icon"
                width={34}
              />
              <img
                src={referralActiveIcon}
                alt="..."
                className="menu-icon1"
                width={34}
              />
              {reverseReferralsCount != 0 ? (
                <Badge pill bg="danger" style={{ position: "absolute" }}>
                  {reverseReferralsCount}
                </Badge>
              ) : null}

              <span className="newheader-span">Reverse Referrals</span>
            </NavLink>
          </li>

          {/* <li className="nav-item nav-list" style={{ position: "relative" }}>
            <NavLink to="/chat" className="nav-linking">
              <img src={chatIcon} alt="..." className="menu-icon" width={34} />
              <img
                src={chatActiveIcon}
                alt="..."
                className="menu-icon1"
                width={34}
              />
              <span className="newheader-span">Chat</span>
            </NavLink>
          </li> */}

          {dropdownItems.map((item, index) => (
            <li
              key={index}
              className={`nav-item nav-list ${
                item.dropdownOpen ? "active" : ""
              }`}
            >
              <div className="nav-linking" onClick={item.toggleDropdown}>
                <i className={`fa ${item.iconClass}`}></i>
                <div className="sub-menus">
                  <span className="newheader-span">{item.title}</span>
                  <i
                    className={`fa fa-chevron-${
                      item.dropdownOpen ? "up" : "down"
                    } dropdown-icon`}
                  ></i>
                </div>
              </div>
              {item.dropdownOpen && (
                <ul className="sub-menu">
                  {item.submenuItems.map((submenuItem, subIndex) => (
                    <li className="nav-item" key={subIndex}>
                      <NavLink
                        to={submenuItem.path}
                        className="nav-linking"
                        onClick={item.handleCloseDropdown}
                      >
                        <img
                          className="menu-icon"
                          src={submenuItem.icon}
                          alt="..."
                        />
                        <img
                          className="menu-icon1"
                          src={submenuItem.activeIcon}
                          alt="..."
                        />

                        {submenuItem.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <Outlet />
      </div>
    </>
  );
};

export default NewSidebar;

// import React, { useState } from "react";
// import "../assets/css/newsidebar.css";
// import { Outlet, NavLink, Link } from "react-router-dom";
// import { useMyPatient } from "../pages/Mypatient/custom-hook/my-patient-hook";

// const NewSidebar = () => {
//   const [helpDropdownOpen, setHelpDropdownOpen] = useState(false);
//   const [legalDropdownOpen, setLegalDropdownOpen] = useState(false);

//   // Function to toggle the Help dropdown state
//   const toggleHelpDropdown = () => {
//     setHelpDropdownOpen(!helpDropdownOpen);
//     setLegalDropdownOpen(false);
//   };

//   // Function to toggle the Legal dropdown state
//   const toggleLegalDropdown = () => {
//     setLegalDropdownOpen(!legalDropdownOpen);
//     setHelpDropdownOpen(false);
//   };

//   const handleCloseHelpDropdown = () => {
//     setHelpDropdownOpen(false);
//   };

//   const handleCloseLegalDropdown = () => {
//     setLegalDropdownOpen(false);
//   };

//   return (
//     <>
//       {/* <div className="collapse navbar-collapse Newheader-navbar-items" id="navbarSupportedContent"> */}
//       <div className="Newsidebar-div mv_sidebar">
//         <ul className="navbar-nav">
//           <li className="nav-item nav-list">
//             <NavLink to="/dashboard" className="nav-linking ">
//               <i className="fa fa-house-chimney"></i>
//               <span className="newheader-span">Dashboard</span>
//             </NavLink>
//           </li>
//           <li className="nav-item nav-list">
//             <NavLink to="/mypatient" className="nav-linking ">
//               <i className="fa-regular fa-user"></i>
//               <span className="newheader-span">My Patients</span>
//             </NavLink>
//           </li>

//           <li className="nav-item nav-list">
//             <NavLink to="/documents" className="nav-linking">
//               <i className="fa fa-file"></i>
//               <span className="newheader-span">Documents</span>
//             </NavLink>
//           </li>

//           <li className="nav-item nav-list" style={{ position: "relative" }}>
//             <NavLink to="/notification" className="nav-linking">
//               <i className="fa-regular fa-bell"></i>
//               <span className="newheader-span">Notifications</span>
//               {/* <div style={{width: "10px", height: "10px", background: "red", borderRadius: "100%" , position: "absolute", top: "0", right: "32px"}}></div> */}
//             </NavLink>
//           </li>

//           {/* Help dropdown menu item */}
//           <li
//             className={`nav-item nav-list ${helpDropdownOpen ? "active" : ""}`}
//           >
//             <div className="nav-linking" onClick={toggleHelpDropdown}>
//               <i className="fa fa-question-circle"></i>
//               <div className="sub-menus">
//                 <span className="newheader-span">Help</span>
//                 <i
//                   className={`fa fa-chevron-${
//                     helpDropdownOpen ? "up" : "down"
//                   } dropdown-icon`}
//                 ></i>
//               </div>
//             </div>
//             {/* Sub-menu items for Help */}
//             {helpDropdownOpen && (
//               <ul className="sub-menu">
//                 <li className="nav-item">
//                   <NavLink
//                     to="/dashboard"
//                     className="nav-linking"
//                     onClick={handleCloseHelpDropdown}
//                   >
//                     Help Option 1
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     to="/help-option2"
//                     className="nav-linking"
//                     onClick={handleCloseHelpDropdown}
//                   >
//                     Help Option 2
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     to="/help-option3"
//                     className="nav-linking"
//                     onClick={handleCloseHelpDropdown}
//                   >
//                     Help Option 3
//                   </NavLink>
//                 </li>
//               </ul>
//             )}
//           </li>

//           {/* Legal dropdown menu item */}
//           <li
//             className={`nav-item nav-list ${legalDropdownOpen ? "active" : ""}`}
//           >
//             <div className="nav-linking" onClick={toggleLegalDropdown}>
//               <i className="fa fa-gavel"></i>
//               <div className="sub-menus">
//                 <span className="newheader-span">Legal</span>
//                 <i
//                   className={`fa fa-chevron-${
//                     legalDropdownOpen ? "up" : "down"
//                   } dropdown-icon`}
//                 ></i>
//               </div>
//             </div>
//             {/* Sub-menu items for Legal */}
//             {legalDropdownOpen && (
//               <ul className="sub-menu">
//                 <li className="nav-item">
//                   <NavLink
//                     to="/legal-option1"
//                     className="nav-linking"
//                     onClick={handleCloseLegalDropdown}
//                   >
//                     Legal Option 1
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     to="/legal-option2"
//                     className="nav-linking"
//                     onClick={handleCloseLegalDropdown}
//                   >
//                     Legal Option 2
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     to="/legal-option3"
//                     className="nav-linking"
//                     onClick={handleCloseLegalDropdown}
//                   >
//                     Legal Option 3
//                   </NavLink>
//                 </li>
//                 <li className="nav-item">
//                   <NavLink
//                     to="/legal-option4"
//                     className="nav-linking"
//                     onClick={handleCloseLegalDropdown}
//                   >
//                     Legal Option 4
//                   </NavLink>
//                 </li>
//               </ul>
//             )}
//           </li>
//         </ul>
//       </div>
//       {/* </div> */}
//       <div>
//         <Outlet />
//       </div>
//     </>
//   );
// };

// export default NewSidebar;
