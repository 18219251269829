import React from "react";
import { Spinner } from "react-bootstrap";
import "../assets/css/loader.css";
import mygif from "../assets/img/circle-loader.gif";

const Loader = () => {
  return (
    <>
      <div className="text-center  spinnerDiv login-loader">
        <img src={mygif} alt="" />
      </div>
    </>
  );
};

export default Loader;
