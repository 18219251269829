import "../../assets/css/refernewpatient.css";
import { useRefer } from "./custom-hook/refer-hook";
import { Formik, Form as FormikFrom, ErrorMessage, Field } from "formik";
import { validation } from "./validation";
import React, { useEffect, useState } from "react";
import ToastNotification from "../../components/toast-notification";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "../../components/loader";
import uploadImg from "../../assets/img/upload.svg";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const Newpatient = () => {
  const {
    referNewPatientAPI,
    show,
    msg,
    setShow,
    check,
    setCheck,
    previewUrls,
    handleDelete,
    handleFileSelect,
    procedures,
    firstProcedure,
    loading,
    docUploadError,
  } = useRefer();

  // useEffect(() => {
  //   console.log("ppppp", procedures);
  // }, [procedures]);
  const [surgeryDate, setSurgeryDate] = useState("");
  const [dob, setDob] = useState("");
  const today = new Date();

  const formatDate = (date) => {
    if (date) {
      const inputDate = new Date(date);
      const day = inputDate.getDate().toString().padStart(2, "0");
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based, so add 1
      const year = inputDate.getFullYear();

      // const formattedDate = `${month}-${day}-${year}`;
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    }
    return "";
  };

  return (
    <>
      <section className="section mv_main__sec">
        <div className="container-fluid px-0">
          {loading && <Loader />}
          <div className="Page_content new-patient-page mv__new-patient-page ">
            <div className="row mt-0  pt-5 pt-md-0">
              <div>
                {show ? (
                  <ToastNotification
                    setShow={setShow}
                    show={show}
                    content={msg}
                  />
                ) : null}
              </div>
              <div className="col-12">
                <Formik
                  initialValues={{
                    patient_name: "",
                    phone_number: "",
                    procedure_id: "",
                    documents: [],
                    // documents: null,
                    notes: "",
                    same_day_surgery: "0",
                    address: "",
                    city: "",
                    zip: "",
                    st: "",
                    date_of_birth: "",
                    sex: "",
                    surgery_type: "",
                    surgery_date: "",
                  }}
                  validationSchema={validation}
                  onSubmit={async (values, { resetForm }) => {
                    // console.log(values);
                    const newDob = formatDate(values.date_of_birth);
                    const newSurgeryDate = formatDate(values.surgery_date);

                    const request = {
                      ...values,
                      date_of_birth: newDob,
                      surgery_date: newSurgeryDate,
                    };
                    // console.log("requestrequest", request);
                    await referNewPatientAPI(request);
                    resetForm({ values: "" });
                    setCheck(check ? !check : check);
                  }}
                >
                  {({ handleChange, handleBlur, values, setFieldValue }) => (
                    <FormikFrom>
                      <div className="row top-section pb-0">
                        <div className="col-md-3 col-6">
                          <div className="my_patients pb-3 pt-3">
                            <h6 className="mb-0">New Patient</h6>
                          </div>
                        </div>
                        {/* <div className="col-md-8 col-6 text-end ">
                          <button type="submit" className="btn btn-save">
                            Save
                          </button>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Patient Name
                          </label>
                          <input
                            type="text"
                            name="patient_name"
                            value={values.patient_name}
                            className="form-control mv___form_control spacing-row mb-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="patient_name" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            onlyCountries={["us"]}
                            disableDropdown
                            countryCodeEditable={false}
                            country={"us"}
                            name="phone_number"
                            value={values.phone_number}
                            //  onChange={(e)=>handlePhone(e, setFieldValue,)}
                            onChange={(e) => setFieldValue("phone_number", e)}
                          />

                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="phone_number" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            name="address"
                            value={values.address}
                            className="form-control mv___form_control spacing-row mb-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="address" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            name="city"
                            value={values.city}
                            className="form-control mv___form_control spacing-row mb-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="city" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            name="st"
                            value={values.st}
                            className="form-control mv___form_control spacing-row mb-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="st" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Zip
                          </label>
                          <input
                            type="text"
                            name="zip"
                            value={values.zip}
                            className="form-control mv___form_control spacing-row mb-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="zip" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            DOB
                          </label>
                          <DatePicker
                            name="date_of_birth"
                            showIcon
                            selected={values.date_of_birth}
                            // onChange={(date) => setDob(date)}
                            onChange={(date) =>
                              setFieldValue("date_of_birth", date)
                            }
                            maxDate={today}
                            dateFormat="MM-dd-yyyy"
                            className="form-control mv___form_control spacing-row date_field_padding mb-0"
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="date_of_birth" />
                          </div>
                        </div>

                        {/* <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Sex
                          </label>
                          <input
                            type="text"
                            name="sex"
                            value={values.sex}
                            className="form-control mv___form_control spacing-row mb-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="sex" />
                          </div>
                        </div> */}

                        <div className="col-12 col-lg-6">
                          <label htmlFor="gender" className="form-label">
                            Sex
                          </label>
                          <div>
                            <input
                              type="radio"
                              name="sex"
                              value="male"
                              checked={values.sex === "male"}
                              onChange={handleChange}
                              style={{ marginRight: "10px" }}
                            />{" "}
                            <label style={{ marginRight: "15px" }}>Male</label>
                            <input
                              type="radio"
                              name="sex"
                              value="female"
                              checked={values.sex === "female"}
                              onChange={handleChange}
                              style={{ marginRight: "10px" }}
                            />{" "}
                            <label style={{ marginRight: "15px" }}>
                              Female
                            </label>
                            <input
                              type="radio"
                              name="sex"
                              value="other"
                              checked={values.sex === "other"}
                              onChange={handleChange}
                              style={{ marginRight: "10px" }}
                            />{" "}
                            <label style={{ marginRight: "15px" }}>Other</label>
                          </div>
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="sex" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Surgery type
                          </label>
                          <input
                            type="text"
                            name="surgery_type"
                            value={values.surgery_type}
                            className="form-control mv___form_control spacing-row mb-0"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="surgery_type" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Surgery Date
                          </label>
                          <DatePicker
                            name="surgery_date"
                            showIcon
                            // selected={surgeryDate}
                            // onChange={(date) => setSurgeryDate(date)}
                            selected={values.surgery_date}
                            // onChange={(date) => setDob(date)}
                            onChange={(date) =>
                              setFieldValue("surgery_date", date)
                            }
                            minDate={today}
                            className="form-control mv___form_control spacing-row date_field_padding mb-0"
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="surgery_date" />
                          </div>
                        </div>

                        <div className="col-12 col-lg-12">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Procedure
                          </label>
                          <select
                            id="inputState"
                            className="form-select form-control mv___form_control"
                            name="procedure_id"
                            value={values.procedure_id}
                            onChange={handleChange}
                          >
                            <option>Please select procedure</option>
                            {procedures &&
                              procedures.map((item, index) => {
                                const { id, procedure } = item;
                                // console.log("procedureId", procedure);
                                return (
                                  <React.Fragment key={index}>
                                    <option value={`${id}`}>{procedure}</option>
                                  </React.Fragment>
                                );
                              })}
                          </select>
                        </div>

                        <div className="col-md-12">
                          <div className="my-3 pt-0 mt-3">
                            {/* {isDesktop ? ( */}
                            <>
                              <div className="col px-0">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label document-text"
                                  style={{ fontWeight: "unset" }}
                                >
                                  Document (Maximum 15)
                                </label>
                              </div>
                              <div
                                className="col-md-12 position px-0"
                                style={{ position: "relative" }}
                              >
                                <label
                                  htmlFor="et_pb_contact_brand_file_request_0"
                                  className="et_pb_contact_form_label"
                                >
                                  <div style={{ textAlign: "center" }}>
                                    <img src={uploadImg} alt="..." />
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  id="et_pb_contact_brand_file_request_0"
                                  className="file-upload"
                                  name="documents"
                                  onChange={(e) =>
                                    handleFileSelect(
                                      e,
                                      setFieldValue,
                                      values.documents
                                    )
                                  }
                                  // onChange={(e) => {
                                  //   values.documents = e.target.files;
                                  // }}
                                  // onChange={e=>{ handleChange(e); setFieldValue("documents",e.currentTarget.files)}}
                                  multiple
                                />
                                {docUploadError && (
                                  <p style={{ color: "red" }}>
                                    *{docUploadError}
                                  </p>
                                )}

                                <div
                                  className="refferal-img-folder"
                                  style={{ display: "flex" }}
                                >
                                  {previewUrls &&
                                    previewUrls.map((url, index) => (
                                      <div
                                        key={index}
                                        className="mv__patient_box"
                                      >
                                        <div
                                          className="patient-img-holder mv__patient_img_holder"
                                          // style={{
                                          //   width: "90px",
                                          //   height: "90px",
                                          // }}
                                        >
                                          <img
                                            src={url}
                                            alt="..."
                                            style={{
                                              height: "100%",
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                        <button
                                          type="button"
                                          className="mv__trash_icon"
                                          onClick={() =>
                                            handleDelete(
                                              index,
                                              setFieldValue,
                                              values.documents
                                            )
                                          }
                                        >
                                          <div
                                            className="form-icon"
                                            style={{ color: "red" }}
                                          >
                                            {" "}
                                            <i className="fa-solid fa-trash"></i>
                                          </div>
                                        </button>
                                        <div
                                          className="form-icon-new"
                                          // style={{ color: "green", position: "absolute", bottom: 0, right: 0 }}
                                        >
                                          {" "}
                                          <i className="fa fa-check"></i>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </>
                          </div>

                          <div className="col-md-12 mt-4 px-0">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              <span className="notes">Notes</span>{" "}
                              <span className="optional">(optional)</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              placeholder="Enter text here..."
                              rows="3"
                              name="notes"
                              value={values.notes}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          <div className="mt-5 form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                              name="same_day_surgery"
                              // value={check}
                              checked={check}
                              //  value="1"
                              // onChange={handleChange}
                              onChange={(e) => {
                                values.same_day_surgery = e.target.checked
                                  ? "1"
                                  : "0";
                                setCheck(e.target.checked);
                              }}
                            />

                            <label
                              className="form-check-label font-italic "
                              htmlFor="exampleCheck1"
                            >
                              Same (next) day surgery
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 col-6 text-center mt-5 ">
                          <button type="submit" className="btn btn-save">
                            Submit
                          </button>
                        </div>
                      </div>
                    </FormikFrom>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Newpatient;
