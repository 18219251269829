import { callAPI } from "../config/api-core";
import {
  termsOfServiceAPIMethod,
  privacyPolicyAPIMethod,
  privacySettingsAPIMethod,
  privacyPreferencesAPIMethod,
  tickerAPIMethod,
} from "../methods/legal-api-method";

const termsOfServiceAPI = async (request) => {
  const response = await callAPI(
    termsOfServiceAPIMethod,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

const privacyPolicyAPI = async (request) => {
  const response = await callAPI(
    privacyPolicyAPIMethod,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

const privacySettingsAPI = async (request) => {
  const response = await callAPI(
    privacySettingsAPIMethod,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

const privacyPreferencesAPI = async (request) => {
  const response = await callAPI(
    privacyPreferencesAPIMethod,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

const tickerAPI = async (request) => {
  const response = await callAPI(tickerAPIMethod, true, {}, {}, false, false);
  return response;
};

export const getTermsOfService = (request) => termsOfServiceAPI(request);
export const getPrivacyPolicy = (request) => privacyPolicyAPI(request);
export const getPrivacySettings = (request) => privacySettingsAPI(request);
export const getPrivacyPreferences = (request) =>
  privacyPreferencesAPI(request);
export const getTickerAPI = (request) => tickerAPI(request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTermsOfService,
  getPrivacyPolicy,
  getPrivacySettings,
  getPrivacyPreferences,
  getTickerAPI,
};
