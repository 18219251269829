import "../../assets/css/referral-activity.css";
import { useMyPatient } from "./custom-hook/my-patient-hook";
import MyPatientNav from "../../components/my-patient-nav";
import Activity from "../../components/activity";
import MyPatientJourney from "../../components/my-patient-journey";
import MyPatientDocuments from "../../components/my-patient-documents";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import RecordNotFound from "../../components/recorderror";
import api1 from "../../services/api/unreadMsg-api";
import MyPatientChat from "../../components/my-patient-chat";

const Mypatient = () => {
  const param = useParams();
  const {
    myPatientDetails,
    handleDocs,
    document,
    loading,
    showDocTab,
    handleSearch,
    handleKeyDown,
    notFound,
    hitApi,
    selectedPatient,
    handleBack,
    PatientJourney,
    messages,
    activity,
    channels,
    sendMessage,
    journeyList,
    changeDocumentStateAPI,
    showJourneyStateToast,
    setShowJourneyStateToast,
    journeyStateToastMsg,
    documentSearchTerm,
    setDocumentSearchTerm,
    myPatientPageLoading,
    hasMore,
    scrollRef,
    handleTab,
    myPatientAPI,
    searchTerm,
    patientChannel,
    setPatientJourney,
  } = useMyPatient();

  // console.log("msgg", messages)
  // console.log('Rendering my patient index component');
  // console.log("selectedPatient", selectedPatient)

  function getFirstLetters(str) {
    if (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return firstLetters.toUpperCase();
    }
  }

  const handleUnreadMsg = async (notify, id) => {
    // console.log("234567890");
    // console.log("notify", notify);
    // console.log("id", id);
    if (notify == false) {
      try {
        const response = await api1.postUnreadMsg({ refer_patient_id: id });
        if (response && response.data.status == 1) {
          // myPatientAPI()
          const span = window.document.getElementById(`unread_Dot${id}`);
          span.className = "";
          // console.log("span", span);
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <section className="section mv__section_refer">
        <div className="container-fluid px-0">
          {loading && <Loader />}
          {myPatientPageLoading && <p>loading.....</p>}
          <div
            className="referralschatList mv__referralschatList active"
            ref={scrollRef}
          >
            <div className="chatList-inner">
              <div
                className="charlist-inner-content d-flex"
                style={{ justifyContent: " space-between" }}
              >
                <h2>Referrals</h2>
                {Object.keys(param).length > 0 ? (
                  <button
                    type="button btn-bck"
                    className="btn-bck"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                ) : null}
              </div>
              {myPatientDetails.length > 0 && (
                <div className="searchicon chat-searcIcon">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Search Referrals"
                    onChange={(e) => handleSearch(e)}
                    onKeyDown={handleKeyDown}
                    value={searchTerm}
                    // onKeyUp={handleKeyUp}
                  />
                  <i
                    className="fa fa-search"
                    style={{
                      FontSize: "17px",
                      color: "#000",
                      position: "absolute",
                      top: "15px",
                      left: "12px",
                    }}
                    aria-hidden="true"
                  ></i>
                </div>
              )}
            </div>
            <div className="chatUser-list">
              <ul
                className="chatUser-allList mv__chatUser_allList nav nav-pills "
                id="pills-tabs"
                role="tablist"
              >
                {!notFound ? (
                  Object.keys(param).length === 0 ? (
                    myPatientDetails.length > 0 ? (
                      myPatientDetails.map((patient, index) => {
                        // console.log("patient", patient);
                        return (
                          <li
                            className="nav-item"
                            role="presentation"
                            key={index}
                          >
                            <Link
                              className={index === 0 ? "active" : null}
                              to=""
                              onClick={() => {
                                handleDocs(patient.patient_name);
                                handleUnreadMsg(
                                  patient.notifications?.status,
                                  patient.id
                                );
                                // setSelectedPatient(patient);
                              }}
                              id="pills-userone-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-userone"
                              type="button"
                              role="tab"
                              aria-controls="pills-userone"
                              aria-selected="true"
                            >
                              <div className="chatUser-img mv__chatUser-img">
                                {patient.notifications.status == false ? (
                                  <span
                                    className="mv__chatuser_status"
                                    id={`unread_Dot${patient.id}`}
                                  />
                                ) : (
                                  ""
                                )}
                                <p className="mv_fisrtleter">
                                  {getFirstLetters(patient.patient_name)}
                                </p>
                              </div>
                              <div className="chatUser-name text-capitalize">
                                <h2 className="mb-1">{patient.patient_name}</h2>
                                <p className="mobile-view-chatuser mb-1">
                                  {patient.patient_procedure?.procedure}
                                </p>
                                {/* <p className="mobile-view-chatuser">
                                  {patient.phone_number}
                                </p> */}
                              </div>
                            </Link>
                            <span className="mv__chatime">
                              {patient.latest_activity?.created_at_modified}
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      !myPatientPageLoading && !hasMore && <p>no more data</p>
                    )
                  ) : hitApi && myPatientDetails.length > 0 ? (
                    myPatientDetails.map((patient, index) => {
                      // console.log("patient", index);
                      return (
                        <li
                          className="nav-item"
                          role="presentation"
                          key={index}
                        >
                          <Link
                            className={index === 0 ? "active" : null}
                            to=""
                            onClick={() => {
                              handleDocs(patient.patient_name);
                              handleUnreadMsg(
                                patient.notifications?.status,
                                patient.id
                              );
                              // setSelectedPatient(patient);
                            }}
                            data-bs-toggle="pill"
                            data-bs-target="#pills-userone"
                            type="button"
                            role="tab"
                            aria-controls="pills-userone"
                            aria-selected="true"
                          >
                            <div className="chatUser-img mv__chatUser-img  ">
                              <p className="mv_fisrtleter">
                                {getFirstLetters(patient.patient_name)}
                              </p>
                              {/* <img
                                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                                  alt=""
                                /> */}
                            </div>
                            <div className="chatUser-name text-capitalize">
                              <h2>{patient.patient_name}</h2>
                              <p>{patient.patient_procedure.procedure}</p>
                              {/* <p>{patient.phone_number}</p> */}
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <li className="nav-item" role="presentation">
                      <Link
                        className="active"
                        to=""
                        onClick={() => {
                          handleDocs(param.patientName);
                          // setSelectedPatient(patientFromDoc);
                        }}
                        id="pills-userone-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-userone"
                        type="button"
                        role="tab"
                        aria-controls="pills-userone"
                        aria-selected="true"
                      >
                        <div className="chatUser-img mv__chatUser-img  ">
                          <p className="mv_fisrtleter">
                            {getFirstLetters(selectedPatient?.patient_name)}
                          </p>
                          {/* <img
                                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&amp;auto=format&amp;fit=crop&amp;w=500&amp;q=60"
                                  alt=""
                                /> */}
                        </div>
                        <div className="chatUser-name text-capitalize">
                          <h2>{selectedPatient?.patient_name}</h2>
                          <p>{selectedPatient?.patient_procedure?.procedure}</p>
                          {/* <p>
                            {selectedPatient?.phone_number}</p> */}
                        </div>
                      </Link>
                    </li>
                  )
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>

          <div className="tab-content mv__tabs-content" id="pills-tabContents">
            {!notFound ? (
              <div
                className="tab-pane fade show active"
                id="pills-userone"
                role="tabpanel"
                aria-labelledby="pills-userone-tab"
              >
                <div className="Page_content refereal-actity-page mv__Page_content">
                  {/* //Navbar */}
                  <MyPatientNav
                    showDocTab={showDocTab}
                    selectedPatient={selectedPatient}
                    // handleChatTab={handleChatTab}
                  />
                  <div className="row mx-0">
                    <div className="col-md-12 mv_tab_content">
                      <div className="tab-content" id="pills-tabContent">
                        {/* //Activity */}
                        <Activity
                          showDocTab={showDocTab}
                          selectedPatient={selectedPatient}
                          messages={messages}
                          activity={activity}
                          channels={channels}
                        />
                        <div
                          className={
                            showDocTab
                              ? "tab-pane fade "
                              : "tab-pane fade active show"
                          }
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          {/* //Journey */}
                          <MyPatientJourney
                            showDocTab={showDocTab}
                            selectedPatient={selectedPatient}
                            PatientJourney={PatientJourney}
                            setPatientJourney={setPatientJourney}
                            messages={messages}
                            activity={activity}
                            channels={channels}
                            sendMessage={sendMessage}
                            myPatientAPI={myPatientAPI}
                          />

                          {/* ///Journey detail */}
                          {/* <MyPatientJourneyDetails /> */}
                        </div>
                        {/* //Documents */}
                        <MyPatientDocuments
                          document={document}
                          showDocTab={showDocTab}
                          messages={messages}
                          channels={channels}
                          journeyList={journeyList}
                          changeDocumentStateAPI={changeDocumentStateAPI}
                          showJourneyStateToast={showJourneyStateToast}
                          setShowJourneyStateToast={setShowJourneyStateToast}
                          journeyStateToastMsg={journeyStateToastMsg}
                          documentSearchTerm={documentSearchTerm}
                          setDocumentSearchTerm={setDocumentSearchTerm}
                        />
                        <div
                          className="tab-pane fade"
                          id="pills-chat"
                          role="tabpanel"
                          aria-labelledby="pills-chat-tab"
                        >
                          <MyPatientChat
                            patientChannel={patientChannel}
                            selectedPatient={selectedPatient}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <RecordNotFound heading="patient not found" />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Mypatient;
