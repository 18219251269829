import React, { useEffect, useState } from "react";
import { useExistingPatientPost } from "./custom-hook/existing-patient-hook";
import { Link } from "react-router-dom";
import "../../assets/css/existing-patient.css";
import { Formik, Form as FormikFrom, ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import Loader from "../../components/loader";
import uploadImg from "../../assets/img/upload.svg";
import ToastNotification from "../../components/toast-notification";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { validation } from "../Referralnewpatient/validation";
// import gif from "../../assets/doc-box.gif";

const ExistingPatientPost = () => {
  // const [surgeryDate, setSurgeryDate] = useState(null);
  // const [date_of_birth, setDate_of_birth] = useState(null);

  // console.log("date_of_birth", date_of_birth);

  const today = new Date();

  const {
    existingPatients,
    selectedPatient,
    handleSelectedPatient,
    selectedPatientSurgey,
    selectedPatientName,
    selectedPatientPhone,
    setSelectedPatientName,
    setSelectedPatientPhone,
    setSelectedPatientSurgery,
    procedures,
    selectedPatientProcedure,
    setSelectedPatientProcedure,
    loading,
    documents,
    setDocuments,
    notes,
    setNotes,
    handleFileSelect,
    handleDelete,
    previewUrls,
    referNewPatientAPI,
    show,
    msg,
    setShow,
    selectedPatientID,
    docUploadError,
    JourneyList,
    selectedPatientAddress,
    setSelectedPatientAddress,
    selectedPatientCity,
    setSelectedPatientCity,
    selectedPatientSt,
    setSelectedPatientSt,
    selectedPatientZip,
    setSelectedPatientZip,
    selectedPatientSex,
    setSelectedPatientSex,
    selectedPatientDob,
    setSelectedPatientDob,
    selectedPatientSurgeryType,
    setSelectedPatientSurgeryType,
    selectedPatientSurgeryDate,
    setSelectedPatientSurgeryDate,
    loadingGif,
    reverseFormatDate,
  } = useExistingPatientPost();
  // console.log("previewUrls", previewUrls);

  function getFirstLetters(str) {
    if (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return firstLetters.toUpperCase();
    }
  }

  const formatDate = (date) => {
    if (date) {
      const inputDate = new Date(date);
      const day = inputDate.getDate().toString().padStart(2, "0");
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based, so add 1
      const year = inputDate.getFullYear();

      // const formattedDate = `${month}-${day}-${year}`;
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    }
    return "";
  };

  const handleSubmit = async () => {
    const newDob = formatDate(selectedPatientDob);
    const newSurgeryDate = formatDate(selectedPatientSurgeryDate);

    const values = {
      user_id: selectedPatientID,
      patient_name: selectedPatientName,
      phone_number: selectedPatientPhone,
      journey_id: selectedPatientProcedure,
      // procedure_id: selectedPatientProcedure,
      documents,
      notes,
      // same_day_surgery: selectedPatientSurgey,
      address: selectedPatientAddress,
      city: selectedPatientCity,
      zip: selectedPatientZip,
      st: selectedPatientSt,
      date_of_birth: newDob,
      sex: selectedPatientSex,
      surgery_type: selectedPatientSurgeryType,
      surgery_date: newSurgeryDate,
    };
    // console.log("values", values);
    await referNewPatientAPI(values);
  };

  // console.log("loadingGif", loadingGif);

  return (
    <section className="section mv__section_refer">
      {/* <div style={{ zIndex: 12121020121212, position: "absolute" }}>
        {" "}
        <img src={gif} alt="Loading" />
      </div> */}
      <div className="container-fluid px-0">
        {loading && <Loader />}

        {/* {loadingGif && <img src={gif} alt="Loading" />} */}
        {show ? (
          <ToastNotification setShow={setShow} show={show} content={msg} />
        ) : null}
        <div
          className="referralschatList mv__referralschatList active"
          // ref={scrollRef}
          style={{ marginLeft: "0px !important" }}
        >
          <div className="chatList-inner">
            <div
              className="charlist-inner-content d-flex"
              style={{ justifyContent: " space-between" }}
            >
              <h2>Patients</h2>
            </div>
          </div>
          <div className="chatUser-list">
            <ul
              className="chatUser-allList mv__chatUser_allList nav nav-pills "
              id="pills-tabs"
              role="tablist"
            >
              {existingPatients?.map((item, index) => {
                const { patient_name } = item;
                return (
                  <li className="nav-item" role="presentation" key={index}>
                    <Link
                      className={index === 0 ? "active" : null}
                      to=""
                      onClick={() => {
                        handleSelectedPatient(patient_name);
                      }}
                      id="pills-userone-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-userone"
                      type="button"
                      role="tab"
                      aria-controls="pills-userone"
                      aria-selected="true"
                    >
                      <div className="chatUser-img mv__chatUser-img">
                        <p className="mv_fisrtleter">
                          {getFirstLetters(patient_name)}
                        </p>
                      </div>
                      <div className="chatUser-name text-capitalize">
                        <h2 className="mb-1">{patient_name}</h2>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div
          className="tab-content mv__tabs-content custom-margin"
          id="pills-tabContents"
        >
          <div className="Page_content new-patient-page mv__new-patient-page ">
            <div className="row mt-0  pt-0">
              <div className="col-12">
                {selectedPatient && (
                  // <form onSubmit={handleSubmit}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      user_id: selectedPatient.id,
                      patient_name: selectedPatient.patient_name || "",
                      phone_number: selectedPatient.phone_number || "",
                      procedure_id: "",
                      documents: [],
                      // documents: null,
                      notes: "",
                      // same_day_surgery: "0",
                      address: selectedPatient.address || "",
                      city: selectedPatient.city || "",
                      zip: selectedPatient.zip || "",
                      st: selectedPatient.st || "",
                      date_of_birth:
                        reverseFormatDate(selectedPatient?.date_of_birth) || "",
                      sex: selectedPatient.sex || "",
                      surgery_type: selectedPatient.surgery_type || "",
                      surgery_date:
                        reverseFormatDate(selectedPatient?.surgery_date) || "",
                    }}
                    validationSchema={validation}
                    onSubmit={async (values, { resetForm }) => {
                      // console.log("values", values);
                      const newDob = formatDate(values.date_of_birth);
                      const newSurgeryDate = formatDate(values.surgery_date);

                      const request = {
                        ...values,
                        date_of_birth: newDob,
                        surgery_date: newSurgeryDate,
                      };
                      // console.log("request", request);
                      await referNewPatientAPI(request);
                      // resetForm({ values: "" });
                      // setCheck(check ? !check : check);
                    }}
                  >
                    {({ handleChange, handleBlur, values, setFieldValue }) => (
                      <FormikFrom>
                        <div className="row top-section pb-0">
                          <div className="col-md-3 col-6">
                            <div className="my_patients pb-3 ">
                              <h6 className="mb-0">Patient Post OP</h6>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Patient Name
                            </label>
                            <input
                              type="text"
                              name="patient_name"
                              value={values.patient_name}
                              className="form-control mv___form_control spacing-row mb-0"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="patient_name" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Phone Number
                            </label>
                            <PhoneInput
                              onlyCountries={["us"]}
                              disableDropdown
                              countryCodeEditable={false}
                              country={"us"}
                              name="phone_number"
                              value={values.phone_number}
                              //  onChange={(e)=>handlePhone(e, setFieldValue,)}
                              onChange={(e) => setFieldValue("phone_number", e)}
                            />

                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="phone_number" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              name="address"
                              value={values.address}
                              className="form-control mv___form_control spacing-row mb-0"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="address" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              value={values.city}
                              className="form-control mv___form_control spacing-row mb-0"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="city" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              name="st"
                              value={values.st}
                              className="form-control mv___form_control spacing-row mb-0"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="st" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Zip
                            </label>
                            <input
                              type="text"
                              name="zip"
                              value={values.zip}
                              className="form-control mv___form_control spacing-row mb-0"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="zip" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              DOB
                            </label>
                            <DatePicker
                              name="date_of_birth"
                              showIcon
                              selected={values.date_of_birth}
                              // onChange={(date) => setDob(date)}
                              onChange={(date) =>
                                setFieldValue("date_of_birth", date)
                              }
                              maxDate={today}
                              dateFormat="MM-dd-yyyy"
                              // dateFormat="yyyy-MM-dd"
                              className="form-control mv___form_control spacing-row date_field_padding mb-0"
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="date_of_birth" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label htmlFor="gender" className="form-label">
                              Sex
                            </label>
                            <div>
                              <input
                                type="radio"
                                name="sex"
                                value="male"
                                checked={values.sex === "male"}
                                onChange={handleChange}
                                style={{ marginRight: "10px" }}
                              />{" "}
                              <label style={{ marginRight: "15px" }}>
                                Male
                              </label>
                              <input
                                type="radio"
                                name="sex"
                                value="female"
                                checked={values.sex === "female"}
                                onChange={handleChange}
                                style={{ marginRight: "10px" }}
                              />{" "}
                              <label style={{ marginRight: "15px" }}>
                                Female
                              </label>
                              <input
                                type="radio"
                                name="sex"
                                value="other"
                                checked={values.sex === "other"}
                                onChange={handleChange}
                                style={{ marginRight: "10px" }}
                              />{" "}
                              <label style={{ marginRight: "15px" }}>
                                Other
                              </label>
                            </div>
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="sex" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Surgery type
                            </label>
                            <input
                              type="text"
                              name="surgery_type"
                              value={values.surgery_type}
                              className="form-control mv___form_control spacing-row mb-0"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="surgery_type" />
                            </div>
                          </div>

                          <div className="col-12 col-lg-6 mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Surgery Date
                            </label>
                            <DatePicker
                              name="surgery_date"
                              showIcon
                              // selected={surgeryDate}
                              // onChange={(date) => setSurgeryDate(date)}
                              selected={values.surgery_date}
                              // onChange={(date) => setDob(date)}
                              onChange={(date) =>
                                setFieldValue("surgery_date", date)
                              }
                              minDate={today}
                              dateFormat="MM-dd-yyyy"
                              className="form-control mv___form_control spacing-row date_field_padding mb-0"
                            />
                            <div
                              className="empty"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              <ErrorMessage name="surgery_date" />
                            </div>
                          </div>
                          {/* <div className="col-12 col-lg-12">
                            {" "}
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Journey
                            </label>
                            <select
                              id="inputState"
                              className="form-select form-control mv___form_control"
                              name="procedure_id"
                              value={
                                selectedPatientProcedure
                                  ? selectedPatientProcedure
                                  : ""
                              }
                              onChange={(e) =>
                                setSelectedPatientProcedure(e.target.value)
                              }
                            >
                              <option value="">Please select Journey</option>
                              {JourneyList &&
                                JourneyList.map((item, index) => {
                                  const { id, title } = item;

                                  return (
                                    <React.Fragment key={index + id}>
                                      <option value={`${id}`}>{title}</option>
                                    </React.Fragment>
                                  );
                                })}
                            </select>
                          </div> */}
                          <div className="col-12 col-lg-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Procedure
                            </label>
                            <select
                              id="inputState"
                              className="form-select form-control mv___form_control"
                              name="procedure_id"
                              value={values.procedure_id}
                              onChange={handleChange}
                            >
                              <option>Please select procedure</option>
                              {procedures &&
                                procedures.map((item, index) => {
                                  const { id, procedure } = item;
                                  // console.log("procedureId", procedure);
                                  return (
                                    <React.Fragment key={index}>
                                      <option value={`${id}`}>
                                        {procedure}
                                      </option>
                                    </React.Fragment>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-md-12">
                            <div className="my-3 pt-0 mt-3">
                              <>
                                <div className="col px-0">
                                  <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label document-text"
                                    style={{ fontWeight: "unset" }}
                                  >
                                    Document (Maximum 15)
                                  </label>
                                </div>
                                <div
                                  className="col-md-12 position px-0"
                                  style={{ position: "relative" }}
                                >
                                  <label
                                    htmlFor="et_pb_contact_brand_file_request_0"
                                    className="et_pb_contact_form_label"
                                  >
                                    <div style={{ textAlign: "center" }}>
                                      <img src={uploadImg} alt="..." />
                                    </div>
                                  </label>
                                  <input
                                    type="file"
                                    id="et_pb_contact_brand_file_request_0"
                                    className="file-upload"
                                    name="documents"
                                    onChange={(e) =>
                                      handleFileSelect(
                                        e,
                                        setFieldValue,
                                        values.documents
                                      )
                                    }
                                    // onChange={(e) => {
                                    //   values.documents = e.target.files;
                                    // }}
                                    // onChange={e=>{ handleChange(e); setFieldValue("documents",e.currentTarget.files)}}
                                    multiple
                                  />
                                  {docUploadError && (
                                    <p style={{ color: "red" }}>
                                      *{docUploadError}
                                    </p>
                                  )}

                                  <div
                                    className="refferal-img-folder"
                                    style={{ display: "flex" }}
                                  >
                                    {previewUrls &&
                                      previewUrls.map((url, index) => (
                                        <div
                                          key={index}
                                          className="mv__patient_box"
                                        >
                                          <div
                                            className="patient-img-holder mv__patient_img_holder"
                                            // style={{
                                            //   width: "90px",
                                            //   height: "90px",
                                            // }}
                                          >
                                            <img
                                              src={url}
                                              alt="..."
                                              style={{
                                                height: "100%",
                                                width: "100%",
                                              }}
                                            />
                                          </div>
                                          <button
                                            type="button"
                                            className="mv__trash_icon"
                                            onClick={() =>
                                              handleDelete(
                                                index,
                                                setFieldValue,
                                                values.documents
                                              )
                                            }
                                          >
                                            <div
                                              className="form-icon"
                                              style={{ color: "red" }}
                                            >
                                              {" "}
                                              <i className="fa-solid fa-trash"></i>
                                            </div>
                                          </button>
                                          <div
                                            className="form-icon-new"
                                            // style={{ color: "green", position: "absolute", bottom: 0, right: 0 }}
                                          >
                                            {" "}
                                            <i className="fa fa-check"></i>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </>
                            </div>

                            <div className="col-md-12 mt-4 px-0">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                className="form-label"
                              >
                                <span className="notes">Notes</span>{" "}
                                <span className="optional">(optional)</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                placeholder="Enter text here..."
                                rows="3"
                                name="notes"
                                value={values.notes}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12 col-6 text-center mt-5 ">
                            <button type="submit" className="btn btn-save">
                              Submit
                            </button>
                          </div>
                        </div>
                      </FormikFrom>
                    )}
                  </Formik>
                  /* </form> */
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ExistingPatientPost;

{
  /* <div
className="tab-content mv__tabs-content custom-margin"
id="pills-tabContents"
>
<div className="Page_content new-patient-page mv__new-patient-page ">
  <div className="row mt-0  pt-0">
    <div className="col-12">
      {selectedPatient && (
        <form onSubmit={handleSubmit}>
          <div className="row top-section pb-0">
            <div className="col-md-3 col-6">
              <div className="my_patients pb-3 ">
                <h6 className="mb-0">Patient Post OP</h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                Patient Name
              </label>
              <input
                type="text"
                name="patient_name"
                value={selectedPatientName}
                className="form-control mv___form_control spacing-row"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setSelectedPatientName(e.target.value)
                }
              />
              <div
                className="empty"
                style={{
                  color: "#C40000",
                  fontSize: "14px",
                }}
              ></div>
            </div>

            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                Phone Number
              </label>
              <PhoneInput
                onlyCountries={["us"]}
                disableDropdown
                countryCodeEditable={false}
                country={"us"}
                name="phone_number"
                value={selectedPatientPhone}
                onChange={(e) => setSelectedPatientPhone(e)}
              />
            </div>

            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                Address
              </label>
              <input
                type="text"
                // name="address"
                value={selectedPatientAddress}
                className="form-control mv___form_control spacing-row"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setSelectedPatientAddress(e.target.value)
                }
                
              />
              <div
                className="empty"
                style={{ color: "#C40000", fontSize: "14px" }}
              >

              </div>
            </div>

            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                City
              </label>
              <input
                type="text"
                name="city"
                value={selectedPatientCity}
                className="form-control mv___form_control spacing-row"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setSelectedPatientCity(e.target.value)
                }
             
              />
              <div
                className="empty"
                style={{ color: "#C40000", fontSize: "14px" }}
              >
              
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                State
              </label>
              <input
                type="text"
                name="st"
                value={selectedPatientSt}
                className="form-control mv___form_control spacing-row"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => setSelectedPatientSt(e.target.value)}
               
              />
              <div
                className="empty"
                style={{ color: "#C40000", fontSize: "14px" }}
              >
               
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                Zip
              </label>
              <input
                type="text"
                name="zip"
                value={selectedPatientZip}
                className="form-control mv___form_control spacing-row"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setSelectedPatientZip(e.target.value)
                }
               
              />
              <div
                className="empty"
                style={{ color: "#C40000", fontSize: "14px" }}
              >
               
              </div>
            </div>
            <div className="col-12 col-lg-6 mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                DOB
              </label>
              <DatePicker
                name="date_of_birth"
                showIcon
                selected={selectedPatientDob || null}
                // onChange={(date) => setDob(date)}
                onChange={(date) => setSelectedPatientDob(date)}
                maxDate={today}
                dateFormat="MM-dd-yyyy"
                className="form-control mv___form_control spacing-row date_field_padding mb-0"
              />
              <div
                className="empty"
                style={{ color: "#C40000", fontSize: "14px" }}
              >
               
              </div>
            </div>

            

            <div className="col-12 col-lg-6">
              <label htmlFor="gender" className="form-label">
                Sex
              </label>
              <div>
                <input
                  type="radio"
                  name="sex"
                  value="male"
                  checked={selectedPatientSex === "male"}
                  onChange={(e) =>
                    setSelectedPatientSex(e.target.value)
                  }
                  style={{ marginRight: "10px" }}
                />{" "}
                <label style={{ marginRight: "15px" }}>Male</label>
                <input
                  type="radio"
                  name="sex"
                  value="female"
                  checked={selectedPatientSex === "female"}
                  onChange={(e) =>
                    setSelectedPatientSex(e.target.value)
                  }
                  style={{ marginRight: "10px" }}
                />{" "}
                <label style={{ marginRight: "15px" }}>Female</label>
                <input
                  type="radio"
                  name="sex"
                  value="other"
                  checked={selectedPatientSex === "other"}
                  onChange={(e) =>
                    setSelectedPatientSex(e.target.value)
                  }
                  style={{ marginRight: "10px" }}
                />{" "}
                <label style={{ marginRight: "15px" }}>Other</label>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                Surgery type
              </label>
              <input
                type="text"
                name="surgery_type"
                value={selectedPatientSurgeryType}
                className="form-control mv___form_control spacing-row"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setSelectedPatientSurgeryType(e.target.value)
                }
                // onBlur={handleBlur}
              />
              <div
                className="empty"
                style={{ color: "#C40000", fontSize: "14px" }}
              >
               
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                Surgery Date
              </label>
              <DatePicker
                showIcon
                selected={selectedPatientSurgeryDate || null}
                onChange={(date) =>
                  setSelectedPatientSurgeryDate(date)
                }
                className="form-control mv___form_control spacing-row date_field_padding"
              />
              <div
                className="empty"
                style={{ color: "#C40000", fontSize: "14px" }}
              >
               
              </div>
            </div>
            <div className="col-12 col-lg-12">
              {" "}
              <label
                htmlFor="exampleInputEmail1"
                className="form-label"
              >
                Journey
              </label>
              <select
                id="inputState"
                className="form-select form-control mv___form_control"
                name="procedure_id"
                value={
                  selectedPatientProcedure
                    ? selectedPatientProcedure
                    : ""
                }
                onChange={(e) =>
                  setSelectedPatientProcedure(e.target.value)
                }
              >
                <option value="">Please select Journey</option>
                {JourneyList &&
                  JourneyList.map((item, index) => {
                    // console.log("item", item);
                    const { id, title } = item;
                    // console.log("procedureId", procedure);
                    return (
                      <React.Fragment key={index + id}>
                        <option value={`${id}`}>{title}</option>
                      </React.Fragment>
                    );
                  })}
              </select>
            </div>
            <div className="col-md-12">
              <div className="my-3 pt-0 mt-3">
                <>
                  <div className="col px-0">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label document-text"
                      style={{ fontWeight: "unset" }}
                    >
                      Document (Maximum 15)
                    </label>
                  </div>
                  <div
                    className="col-md-12 position px-0"
                    style={{ position: "relative" }}
                  >
                    <label
                      htmlFor="et_pb_contact_brand_file_request_0"
                      className="et_pb_contact_form_label"
                    >
                      <div style={{ textAlign: "center" }}>
                        <img src={uploadImg} alt="..." />
                      </div>
                    </label>
                    <input
                      type="file"
                      id="et_pb_contact_brand_file_request_0"
                      className="file-upload"
                      name="documents"
                      onChange={(e) => handleFileSelect(e, documents)}
                      multiple
                    />
                    {docUploadError && (
                      <p style={{ color: "red" }}>
                        *{docUploadError}
                      </p>
                    )}
                    <div
                      className="refferal-img-folder"
                      style={{ display: "flex" }}
                    >
                      {previewUrls &&
                        previewUrls.map((url, index) => (
                          <div
                            key={index}
                            className="mv__patient_box"
                          >
                            <div className="patient-img-holder mv__patient_img_holder">
                              <img
                                src={url}
                                alt="..."
                                style={{
                                  height: "100%",
                                  width: "100%",
                                }}
                              />
                            </div>
                            <button
                              type="button"
                              className="mv__trash_icon"
                              onClick={() =>
                                handleDelete(index, documents)
                              }
                            >
                              <div
                                className="form-icon"
                                style={{ color: "red" }}
                              >
                                {" "}
                                <i className="fa-solid fa-trash"></i>
                              </div>
                            </button>
                            <div className="form-icon-new">
                              {" "}
                              <i className="fa fa-check"></i>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </>
              </div>

              <div className="col-md-12 mt-4 px-0">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  <span className="notes">Notes</span>{" "}
                  <span className="optional">(optional)</span>
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  placeholder="Enter text here..."
                  rows="3"
                  name="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-md-12 col-6 text-center mt-5 ">
              <button
                type="button"
                className="btn btn-save"
                onClick={handleSubmit}
                disabled={
                  !selectedPatientName || !selectedPatientPhone
                }
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  </div>
</div>
</div> */
}
