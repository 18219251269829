import { callAPI } from "../config/api-core";
import {
  reverseReferralsListAPIMethod,
  reverseReferralsBadgeAPIMethod,
  reverseReferralsNotifiedAPIMethod,
} from "../methods/reverse-referrals-api-method";

const reverseReferralsListAPI = async (request) => {
  let param = `?page=${request.page_no}&per_page=${request.limit}&search_keyword=${request.keyword}`;
  const response = await callAPI(
    reverseReferralsListAPIMethod + param,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

const reverseReferralsBadgeAPI = async (request) => {
  const response = await callAPI(
    reverseReferralsBadgeAPIMethod,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

const reverseReferralsNotifiedAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    "Content-Type": "application/json",
    Authorization: userToken && `Bearer ${token}`,
  };

  const response = await callAPI(
    reverseReferralsNotifiedAPIMethod,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

export const getReversereferralsList = (request) =>
  reverseReferralsListAPI(request);

export const getReversereferralsBadge = (request) =>
  reverseReferralsBadgeAPI(request);

export const postReversereferralsNotified = (request) =>
  reverseReferralsNotifiedAPI(request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getReversereferralsList,
  getReversereferralsBadge,
  postReversereferralsNotified,
};
