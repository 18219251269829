import { callAPI } from "../config/api-core";
import { activityAPIMethods} from "../methods/activity-api-method";

const activityFetch = async (request) => {
    let params = `?refer_patient_id=${request}`
  const response = await callAPI(
    activityAPIMethods+params,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

export const getActivityAPI = (request) => activityFetch(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getActivityAPI,
};
