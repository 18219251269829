import * as Yup from "yup";

export const validation = Yup.object().shape({
  patient_name: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .required(" Patient Name is required")
    .max(20, "Maximum 20 characters allowed"),
  phone_number: Yup.number()
    // .typeError("That doesn't look like a phone number")
    // .positive("A phone number can't start with a minus")
    // .integer("A phone number can't include a decimal point")
    .required("Phone Number is required"),
  // procedure: Yup.string().required(" procedure field required"),
  // documents: Yup.array()
  //   .of(
  //     Yup.mixed()
  //       .test(
  //         "fileFormat",
  //         "Invalid file format",
  //         (value) =>
  //           value &&
  //           (value.type === "image/jpeg" ||
  //             value.type === "image/jpg" ||
  //             value.type === "image/png")
  //       )
  //       .required("File is required")
  //   )
  //   .min(1, "At least one file is required")
  //   .required("At least one file is required"),
  date_of_birth: Yup.date().required("DOB is required"),
  surgery_date: Yup.date().required("Surgery Date is required"),
  address: Yup.string().required("Address field is required"),
  city: Yup.string().required("City field is required"),
  zip: Yup.string().required("Zip field is required"),
  st: Yup.string().required("State field is required"),
  // sex: Yup.string().required("Sex field is required"),
  sex: Yup.string()
    .oneOf(["male", "female", "other"], "Please select a valid sex")
    .required("Sex field is required"),
  surgery_type: Yup.string().required("Surgery type field is required"),
});
