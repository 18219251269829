import { useContext, useEffect, useRef, useState } from "react";
import "../../assets/css/reverse-referral.css";

import api from "../../services/api/reverse-referrals-api";
import Loader from "../../components/loader";
import RecordNotFound from "../../components/recorderror";
import Pagination from "../../components/pagination";
import { UserContext } from "../../App";
import { Spinner } from "react-bootstrap";

function ReverseReferral() {
  const [loading, setLoading] = useState(false);
  const [reverseReferralsList, setReverseReferralsList] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [hitApi, setHitApi] = useState(false);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [notifiedLoading, setNotifiedLoading] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState(null);

  const hasMounted = useRef(false);
  const { setReverseReferralsCount } = useContext(UserContext);
  console.log("reverseReferralsList", reverseReferralsList);

  const fetchReverseReferralList = async (pageNo, searchKey) => {
    let request = {
      page_no: pageNo || 1,
      limit: 6,
      keyword: searchKey || "",
    };
    setLoading(true);
    try {
      const response = await api.getReversereferralsList(request);
      // console.log("Response", response);
      if (response && response.data.data) {
        setReverseReferralsList(response.data.data.reverseReferral);
        setTotalDocuments(response.data.data.reverseReferral.total);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasMounted.current) {
      fetchReverseReferralList(currentPage, "");
      hasMounted.current = true;
    }
  }, []);

  function getFirstLetters(str) {
    if (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return firstLetters.toUpperCase();
    }
  }

  const handleKeyDown = (e) => {
    // if (e.key === 13) {
    if (
      e.key === "Enter" &&
      searchKey !== undefined &&
      reverseReferralsList?.data.length > 0
    ) {
      setHitApi(true);
      setCurrentPage(1);
      fetchReverseReferralList(1, searchKey);
    }
    // }
  };

  useEffect(() => {
    let timerId;
    if (hitApi && searchKey.length === 0) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        setHitApi(false);
        setCurrentPage(1);
        fetchReverseReferralList(currentPage, searchKey);
      }, 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [searchKey]);

  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    await fetchReverseReferralList(pageNumber, searchKey);
  };

  const handleNextbtn = async () => {
    setCurrentPage(currentPage + 1);
    await fetchReverseReferralList(currentPage + 1, searchKey);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = async () => {
    setCurrentPage(currentPage - 1);
    await fetchReverseReferralList(currentPage - 1, searchKey);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const fetchReverseReferralNotified = async (id) => {
    let request = {
      referral_id: id,
      is_notified: "1",
    };
    setNotifiedLoading(true);
    try {
      const response = await api.postReversereferralsNotified(request);
      if (response && response.data.status == 1) {
        setReverseReferralsCount((prev) => prev - 1);
        const updatedReverseReferralsList = reverseReferralsList.data.map(
          (item) => {
            if (item.id === id) {
              item.is_notified = "1";
            }
            return item;
          }
        );
        setReverseReferralsList({
          ...reverseReferralsList,
          data: updatedReverseReferralsList,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setNotifiedLoading(false);
    }
  };

  const handleNotified = (id) => {
    setClickedButtonId(id);
    fetchReverseReferralNotified(id);
  };

  return (
    <>
      <section className="section home-page-section mv_main__sec">
        <div className="container-fluid px-0">
          <div className="Page_content notification_page_ui pt-0">
            {loading && <Loader />}
            <div className="container-fluid">
              <div className="top_heading row my-0  pt-0">
                <div className="col-md-6 my-4">
                  <div className="Document_text">
                    <h6>Reverse Referrals</h6>
                  </div>
                  {/* {reverseReferralsList &&
                  reverseReferralsList.data.length > 0 ? ( */}
                  <div class="searchicon chat-searcIcon">
                    <input
                      type="text"
                      class="form-control"
                      onChange={(e) => setSearchKey(e.target.value)}
                      placeholder="Search "
                      value={searchKey}
                      // onKeyUp={handleKeyUp}
                      onKeyDown={handleKeyDown}
                    />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                  {/* ) : null} */}
                </div>
              </div>
              {reverseReferralsList && reverseReferralsList.data.length > 0 ? (
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      {reverseReferralsList.data.map((item, index) => {
                        // console.log("items", item);
                        const {
                          patient_name,
                          patient_phone_number,
                          patient_email,
                          notes,
                          is_notified,
                          id,
                        } = item;
                        return (
                          <div className="col-12 col-md-6" key={index}>
                            <div className="inner-content primary_card card_boxInfo">
                              <div className="dj mt-0">
                                <p>{getFirstLetters(patient_name)}</p>
                              </div>
                              <div className="dr-james border-0 p-0">
                                <h6 className="">
                                  <span className="highlight-text">
                                    {patient_name}
                                  </span>
                                </h6>
                                <div>
                                  <span class="icon mv__link_textIcon icon_file">
                                    <em className="fa fa-file"></em>
                                  </span>{" "}
                                  <small className="info_useremail">
                                    +{patient_phone_number}
                                  </small>
                                </div>
                                <div>
                                  <span class="icon mv__link_textIcon icon_enevelope">
                                    <em className="fa fa-envelope"></em>
                                  </span>{" "}
                                  <small className="info_useremail">
                                    {patient_email}
                                  </small>
                                </div>
                                <div className="discription_box">
                                  <p className="last-seen">{notes}</p>

                                  <span className="info_icon">
                                    <button
                                      type="button"
                                      // className="btn btn-save-outline"
                                      className={`btn ${
                                        is_notified == 1
                                          ? "btn-save-outline-disabled"
                                          : "btn-save-outline"
                                      }`}
                                      onClick={() => handleNotified(id)}
                                      disabled={is_notified == 1}
                                    >
                                      {notifiedLoading &&
                                      clickedButtonId === id ? (
                                        <>
                                          {" "}
                                          Notified
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                            style={{ marginLeft: "5px" }}
                                          />
                                        </>
                                      ) : (
                                        "Notified"
                                      )}
                                    </button>
                                    {/* <em
                                        className="fa fa-info-circle"
                                        onClick={() => handleNotified(id)}
                                        disabled={is_notified == 1}
                                      ></em> */}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* <div className="col-12 col-md-6">
                        <div className="inner-content primary_card card_boxInfo">
                          <div className="dj mt-0">
                            <p>K</p>
                          </div>
                          <div className="dr-james border-0 p-0">
                            <h6 className="">
                              <span className="highlight-text">kamal</span>
                            </h6>
                            <div>
                              <span class="icon mv__link_textIcon icon_file">
                                <em className="fa fa-file"></em>
                              </span>{" "}
                              <small className="info_useremail">
                                123-435-765
                              </small>
                            </div>
                            <div>
                              <span class="icon mv__link_textIcon icon_enevelope">
                                <em className="fa fa-envelope"></em>
                              </span>{" "}
                              <small className="info_useremail">
                                parrysurpanch@gmail.com
                              </small>
                            </div>
                            <div className="discription_box">
                              <p className="last-seen">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type and scrambled it to make a type specimen
                                book. Lorem Ipsum is simply dummy text of the
                                printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever
                                since the 1500s, when an unknown printer took a
                                galley of type and scrambled it to make a type
                                specimen book. Lorem Ipsum is simply dummy text
                                of the printing and typesetting industry. Lorem
                                Ipsum has been the industry's standard dummy
                                text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it
                                to make a type specimen book. Lorem Ipsum is
                                simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the
                                industry's standard dummy text ever since the
                                1500s, when an unknown printer took a galley of
                                type and scrambled it to make a type specimen
                                book.
                              </p>
                              <span className="info_icon">
                                <em className="fa fa-info-circle"></em>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <Pagination
                      totalRecord={totalDocuments}
                      maxPageNumberLimit={maxPageNumberLimit}
                      minPageNumberLimit={minPageNumberLimit}
                      paginate={paginate}
                      handleNextbtn={handleNextbtn}
                      handlePrevbtn={handlePrevbtn}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              ) : (
                <RecordNotFound heading="Reverse Referrals data not found" />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReverseReferral;
