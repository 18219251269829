import React, { memo, useEffect, useState } from "react";
import img1 from "../assets/img/comment-box-icon.svg";
import img2 from "../assets/img/document-orange-icon.svg";
import api from "../services/api/referral-patients-list-api";
import MyPatientJourneyDetails from "./my-patient-journey-details";
import RecordNotfound from "./recorderror";
import NewJourneyDetail from "./newJourneyDetail";
import { Link } from "react-router-dom";
import JourneyNotes from "./journeyNotes";
import JourneyDocuments from "./journeyDocuments";
import radioImg from "../assets/img/radio.svg";
import radioImg1 from "../assets/img/radio1.svg";
import addIcon from "../assets/icons/add-icon.png";
import { Button, Modal, Spinner } from "react-bootstrap";
import ToastNotification from "./toast-notification";

const MyPatientJourney = ({
  selectedPatient,
  PatientJourney,
  messages,
  channels,
  sendMessage,
  setPatientJourney,
  myPatientAPI,
}) => {
  // console.log("MyPatientJourney component rendered", PatientJourney);
  const [showJourneyNotes, setShowJourneyNotes] = useState(false);
  const [showJourneyDocuments, setShowJourneyDocuments] = useState(false);
  const [journeyNotes, setJourneyNotes] = useState([]);
  const [journeyDocuments, setJourneyDocuments] = useState([]);
  const [journeyId, setJourneyId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [newDocuments, setNewDocuments] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [msg, setMsg] = useState("");
  const [showNotesToast, setShowNotesToast] = useState(false);
  const [showDocToast, setShowDocToast] = useState(false);
  const [journeyName, setJourneyName] = useState("");
  const [renderAddModal, setRenderAddModal] = useState(false);
  const [addNewJourneyLoading, setAddNewJourneyLoading] = useState(false);
  const [addNewJourneyDescription, setAddNewJourneyDescription] = useState("");
  const [showNewJourneyToast, setShowNewJourneyToast] = useState(false);

  // console.log("apimessage", selectedPatient);

  const handleShowJourneyNotes = (e, notes, id, status, patient_id) => {
    e.preventDefault();
    setJourneyId(id);
    setJourneyNotes(notes);
    setPatientId(patient_id);
    setShowJourneyNotes(true);
  };

  const handleShowJourneyDocuments = (
    e,
    documents,
    id,
    status,
    patient_id,
    journeyTitle
  ) => {
    e.preventDefault();
    setJourneyId(id);
    setJourneyName(journeyTitle);
    setJourneyDocuments(documents);
    setPatientId(patient_id);
    setShowJourneyDocuments(true);
  };

  const noteCountFromApi = (journey_id, patient_id) => {
    let count = "";
    // console.log("j")
    for (const element of messages) {
      if (
        element.channel === channels[0] &&
        element.message.patient_id == patient_id &&
        element.message.journey_id == journey_id &&
        element.message.type === "add_notes"
      ) {
        count = element.message.notes_count;
        // console.log("jj")
      }
      // console.log("countttt",count )
      // console.log("jjj")
    }
    // console.log("jjjj")
    return count;
  };

  const docCountFromApi = (journey_id, patient_id) => {
    let count = "";
    for (const element of messages) {
      if (
        element.channel === channels[0] &&
        element.message.patient_id == patient_id &&
        element.message.journey_id == journey_id &&
        element.message.type === "add_documents"
      ) {
        count = element.message.documents_count;
      }
    }
    return count;
  };

  const statusFromApi = (journey_id, patient_id) => {
    let status;
    for (const element of messages) {
      if (
        element.channel === channels[0] &&
        element.message.patient_id == patient_id &&
        element.message.journey_id == journey_id &&
        element.message.type === "change_status"
      ) {
        // console.log("entering");
        // console.log("element", element)
        // console.log(element.message.status)
        status = element.message.status;
      }
    }
    return status;
  };

  // const handleImageClick = () => {
  //   // Your click handling logic here
  //   console.log("Image clicked!");
  // };

  const currentDate = new Date();

  // Define months as an array
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format the date
  const formattedDate = `Post-op ${
    months[currentDate.getMonth()]
  } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;

  const handleCreateNewJourney = async () => {
    let request = {
      patient_id: selectedPatient.id,
      description: addNewJourneyDescription,
    };
    setAddNewJourneyLoading(true);
    try {
      const response = await api.postNewJourneyAPI(request);
      // console.log("response", response);
      if (response && response.data.status == 1) {
        //response.data.data.new_journey
        const newPathway = [...PatientJourney];
        newPathway.push(response.data.data.new_journey[0]);
        // console.log("newPathway", newPathway);
        setPatientJourney(newPathway);
        setRenderAddModal(false);
        setShowNewJourneyToast(true);
        setMsg(response.data.message);

        // setTimeout(() => {
        //   myPatientAPI({ page: 1 });
        // }, 3000);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddNewJourneyLoading(false);
    }
  };

  return (
    <>
      {showNewJourneyToast ? (
        <ToastNotification
          content={msg}
          setShow={setShowNewJourneyToast}
          show={showNewJourneyToast}
        />
      ) : null}
      {PatientJourney.length > 0 ? (
        <>
          {PatientJourney.map((item, index) => {
            const {
              journey_id,
              patient_notes,
              status,
              journey_data,
              documents,
              patient_id,
            } = item;

            let pubNubStatus = statusFromApi(journey_id, patient_id);
            let newStatusYes;
            let newStatusNo;
            switch (true) {
              case pubNubStatus == 1:
                newStatusYes = true;
                newStatusNo = false;
                break;
              case pubNubStatus == 0:
                newStatusYes = false;
                newStatusNo = true;
                break;
              // case pubNubStatus == 2:
              //   newStatusYes = false;
              //   newStatusNo = false;
              //   break;
              case pubNubStatus == undefined || pubNubStatus == null:
                switch (status) {
                  case 1:
                    newStatusYes = true;
                    newStatusNo = false;
                    break;
                  case 0:
                    newStatusYes = false;
                    newStatusNo = true;
                    break;
                  case 2:
                    newStatusYes = false;
                    newStatusNo = false;
                    break;
                }
                break;
            }
            // console.log("pubNubStatus", pubNubStatus);
            // console.log("status", status);
            // console.log("newStatusYes", newStatusYes);
            // console.log("newStatusNo", newStatusNo);

            let noteCount = noteCountFromApi(journey_id, patient_id);
            let notesCount = noteCount
              ? noteCount
              : patient_notes?.length == undefined
              ? 0
              : patient_notes.length;
            // let notesCount = patient_notes.length == undefined ? 0 : patient_notes.length;
            let documentCount = docCountFromApi(journey_id, patient_id);
            let documentsCount = documentCount
              ? documentCount
              : documents?.length == undefined
              ? 0
              : documents.length;

            // console.log("noteCount", noteCount);
            // console.log("notesCount", notesCount);
            // console.log("documentCount", documentCount);
            // console.log("documentsCount", documentsCount);

            // console.log("journey_data", journey_data?.id);
            // console.log("journey_id", journey_id);

            return (
              <React.Fragment key={index}>
                <section
                  className="Patient_journy_detail nav"
                  id="pills-tab"
                  role="tablist"
                >
                  <div
                    className="activity_card"
                    // id="pills-popone-tab"
                    // data-bs-toggle="pill"
                    // data-bs-target="#pills-popone"
                    // type="button"
                    // role="tab"
                    // aria-controls="pills-popone"
                    // aria-selected="true"
                  >
                    <ul className="checkedmark">
                      <li
                      // className={
                      //   (pubNubStatus != 2 && pubNubStatus != undefined) ||
                      //   status != 2
                      //     ? ""
                      //     : "message_file_comon_icon_disabledButton"
                      // }
                      >
                        <div className="Contacted_card">
                          <div
                            className="d-flex align-items-center mb-2"
                            style={{ gap: "15px" }}
                          >
                            {status == 1 ||
                            // status == 0 ||
                            pubNubStatus == 1 ? (
                              // ||  pubNubStatus == 0
                              <img src={radioImg} alt=".." width={20} />
                            ) : (
                              <img src={radioImg1} alt=".." width={20} />
                            )}
                            {/* <input
                              type="checkbox"
                              checked={
                                status == 1 ||
                                status == 0 ||
                                pubNubStatus == 1 ||
                                pubNubStatus == 0
                              }
                              readOnly
                            />
                            <label for="checkbox"></label> */}
                            <h6 className="mb-0 pb-0">{journey_data.title}</h6>
                          </div>
                          <div
                            className="Contacted_inner"
                            style={{ paddingLeft: "35px" }}
                          >
                            {/* <div className="Radio_button">
                              <div>
                                <input
                                  type="radio"
                                  // xid="Green"
                                  // name={`journey_id_${journey_data.id}`}
                                  // value="1"
                                  // checked={status == 1 ? true : false}
                                  checked={newStatusYes}
                                  readOnly
                                />
                                <label>Yes</label>
                              </div>
                              <div className="form__radio-group">
                                <input
                                  type="radio"
                                  // name={`journey_id_${journey_data.id}`}
                                  // value="0"
                                  // checked={status == 0 ? true : false}
                                  checked={newStatusNo}
                                  readOnly
                                />
                                <label>No</label>
                              </div>
                            </div> */}
                            <div className="message_file_comon_icon">
                              {/* //  */}
                              <div>
                                <Link
                                  to=""
                                  className="nav-link  chat_text_color mv__link_text pt-0"
                                  onClick={(e) =>
                                    handleShowJourneyNotes(
                                      e,
                                      patient_notes,

                                      journey_id,
                                      status,
                                      patient_id
                                    )
                                  }
                                >
                                  <span className="icon mv__link_textIcon">
                                    <img
                                      src={img1}
                                      alt=""
                                      style={{ width: "20px" }}
                                    />
                                  </span>{" "}
                                  {notesCount}
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to=""
                                  className="nav-link  chat_text_color mv__link_text pt-0"
                                  onClick={(e) =>
                                    handleShowJourneyDocuments(
                                      e,
                                      documents,
                                      journey_id,
                                      status,
                                      patient_id,
                                      journey_data?.title
                                    )
                                  }
                                >
                                  <span className="icon mv__link_textIcon">
                                    <img
                                      src={img2}
                                      alt=""
                                      style={{
                                        paddingLeft: "35px",
                                        margin: "0",
                                        width: "52px",
                                      }}
                                    />
                                  </span>{" "}
                                  {documentsCount}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <p className="discription">
                            {/* There are many variations of passages of Lorem Ipsum
                          available, but the majority have suffered alteration
                          .. */}
                            {journey_data.description}
                          </p>
                        </div>
                      </li>
                    </ul>
                    {item.patient_journey ? (
                      <div>
                        <p className="mb-0">
                          {item.patient_journey?.date_label}
                        </p>
                        <p className="mb-0">
                          {item.patient_journey?.time_label}
                        </p>
                        <p className="mb-0">{item.patient_journey?.location}</p>
                      </div>
                    ) : null}
                  </div>
                </section>

                {/* <NewJourneyDetail
                  patientId={patient_id}
                  journeyId={journey_id}
                  notes={patient_notes}
                  documents={documents}
                  showJourneyDetail={showJourneyDetail}
                  setShowJourneyDetail={setShowJourneyDetail}
                  newDocuments={newDocuments}
                  previewUrls={previewUrls}
                  setNewDocuments={setNewDocuments}
                  setPreviewUrls={setPreviewUrls}
                  setMsg={setMsg}
                  msg={msg}
                  showToast={showToast}
                  setShowToast={setShowToast}
                  messages={messages}
                  channels={channels}
                  // sendMessage={sendMessage}
                /> */}
              </React.Fragment>
            );
          })}
          <section
            className="Patient_journy_detail nav"
            id="pills-tab"
            role="tablist"
          >
            <div className="activity_card">
              <ul className="checkedmark">
                <li>
                  <div className="Contacted_card">
                    <div
                      className="d-flex align-items-center mb-2"
                      style={{ gap: "15px", paddingLeft: "35px" }}
                    >
                      <h6 className="mb-0 pb-0">Add Post-Op</h6>
                    </div>
                    <div
                      className="Contacted_inner"
                      onClick={() => setRenderAddModal(true)}
                      style={{ cursor: "pointer", paddingLeft: "35px" }}
                    >
                      <img src={addIcon} alt="..." />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
          {showJourneyNotes ? (
            <JourneyNotes
              patientId={patientId}
              journeyId={journeyId}
              notes={journeyNotes}
              showJourneyDetail={showJourneyNotes}
              setShowJourneyDetail={setShowJourneyNotes}
              setMsg={setMsg}
              msg={msg}
              showNotesToast={showNotesToast}
              setShowNotesToast={setShowNotesToast}
              messages={messages}
              channels={channels}
              sendMessage={sendMessage}
            />
          ) : null}
          {showJourneyDocuments ? (
            <JourneyDocuments
              patientId={patientId}
              journeyId={journeyId}
              journeyName={journeyName}
              documents={journeyDocuments}
              showJourneyDetail={showJourneyDocuments}
              setShowJourneyDetail={setShowJourneyDocuments}
              newDocuments={newDocuments}
              previewUrls={previewUrls}
              setNewDocuments={setNewDocuments}
              setPreviewUrls={setPreviewUrls}
              setMsg={setMsg}
              msg={msg}
              showDocToast={showDocToast}
              setShowDocToast={setShowDocToast}
              messages={messages}
              channels={channels}
              sendMessage={sendMessage}
            />
          ) : null}
          {/* {showJourneyDetail ? (
            <NewJourneyDetail
              patientId={patientId}
              journeyId={journeyId}
              notes={journeyNotes}
              documents={journeyDocuments}
              showJourneyDetail={showJourneyDetail}
              setShowJourneyDetail={setShowJourneyDetail}
              newDocuments={newDocuments}
              previewUrls={previewUrls}
              setNewDocuments={setNewDocuments}
              setPreviewUrls={setPreviewUrls}
              setMsg={setMsg}
              msg={msg}
              showToast={showToast}
              setShowToast={setShowToast}
              messages={messages}
              channels={channels}
              sendMessage={sendMessage}
            />
          ) : null} */}
        </>
      ) : (
        // <NewJourneyDetail/>
        // <h2>No Journey Found</h2>
        <RecordNotfound heading="Journey not found" />
      )}

      <Modal
        show={renderAddModal}
        onHide={() => setRenderAddModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Post-Op</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {formattedDate}</p>
          <label>Description</label>
          <textarea
            className="form-control"
            rows={5}
            onChange={(e) => setAddNewJourneyDescription(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* {" "}
          <Button variant="secondary" onClick={() => setRenderAddModal(false)}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleCreateNewJourney}>
            {addNewJourneyLoading ? (
              <>
                Create
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                />
              </>
            ) : (
              "Create"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default memo(MyPatientJourney);
