import "../../assets/css/page-error.css";
import img from "../../assets/img/page-error-pic.png";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <div className="page-error-div">
        <div className="page-error-after">
          <div className="page-inner-content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-6 justify-content-center">
                  <div className="img-holder">
                    <img src={img} alt=""></img>
                  </div>
                </div>
                <div className="col-6">
                  <div className="page-error-content">
                    <h2>Sorry , Page not found</h2>
                    {/* <p>The page you requested could not be found.</p> */}
                  </div>
                  <div className="page-error-btn">
                    <Link to="/" className="btn-link">
                      Go to Home Page
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageNotFound;
