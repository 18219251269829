import { memo } from "react";
import img from "../assets/img/patient.png";

const MyPatientNav = ({
  showDocTab,
  selectedPatient,
  handleTab,
  handleChatTab,
}) => {
  function getFirstLetters(str) {
    if (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return firstLetters.toUpperCase();
    }
  }

  // console.log("showDocTab", showDocTab);

  return (
    <div className="Aidan_wrapper mv__activity_wrapper">
      <div className="row mx-0">
        <div className="mv__bothsec">
          <div className="top_section border-0 mv__chatuserr_name">
            <div
              className="Patient_img mv__Patient_img me-3 "
              style={{ marginLeft: "10px" }}
            >
              {/* <img src={img} alt="" /> */}
              <p className="mv_fisrtleter ">
                {getFirstLetters(selectedPatient?.patient_name)}
              </p>
            </div>
            <div className="Aidan_Nathan">
              <div className="Aidan_Nathan_inner text-capitalize">
                <h6>
                  {/* {Object.keys(param).length === 0
                ? selectedPatient?.patient_name
                : patientFromDoc?.patient_name} */}
                  {selectedPatient?.patient_name}
                </h6>
                <p className="Cataract_text">
                  {/* {Object.keys(param).length === 0
                ? selectedPatient?.patient_procedure?.procedure
                : patientFromDoc?.patient_procedure?.procedure} */}
                  {selectedPatient?.patient_procedure?.procedure}
                </p>
              </div>
            </div>
          </div>
          <div className="top_button mv__chatuserr_button">
            <ul
              className="nav nav-pills mv_nav__pills"
              id="pills-tab"
              role="tablist"
            >
              {/* <li className="nav-item" role="presentation">
                <button
                  className={showDocTab ? "nav-link" : "nav-link active"}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={showDocTab ? "false" : "true"}
                >
                  Activity
                </button>
              </li> */}
              <li className="nav-item" role="presentation">
                <button
                  className={showDocTab ? "nav-link" : "nav-link active"}
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected={showDocTab ? "false" : "true"}
                  // onClick={(e)=>handleTab(e,"journey")}
                >
                  Pathway
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={showDocTab ? "nav-link active" : "nav-link"}
                  id="pills-contact-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-contact"
                  type="button"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected={showDocTab ? "true" : "false"}
                  // onClick={(e)=>handleTab(e,"documents")}
                >
                  Documents
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={showDocTab ? "nav-link active" : "nav-link"}
                  // onClick={handleChatTab("show")}
                  id="pills-chat-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-chat"
                  type="button"
                  role="tab"
                  aria-controls="pills-chat"
                  // aria-selected={showDocTab ? "true" : "false"}
                >
                  Notes
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={showDocTab ? "nav-link active" : "nav-link"}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected={showDocTab ? "true" : "false"}
                >
                  Activity
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="col-12">
          <div className="mv__notes my-2">
            <p className="m-0 mv__notess">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <span className="mv__moreText">View more</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default memo(MyPatientNav);
