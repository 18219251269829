import img1 from "../../assets/img/manson-logo.png";
import img2 from "../../assets/img/main-img.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FormikFrom, ErrorMessage } from "formik";
import { validation } from "./validation";
import api from "../../services/api/otp-request-api";
import { useState } from "react";

const ForgotPassword = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const OtpRequest = async (request) => {
    try {
      const response = await api.requestOtp(request);
      // console.log("Response", response);
      if (response && response.data.status == 0) {
        setErrorMsg(response.data.message);
      } else {
        navigate("/change-password", { state: { data: request.email } });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="Login_section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="row main_row">
              <div className="col-md-6">
                <div className="left_side">
                  <div className="logo_card mb-4 ms-0">
                    <img src={img1} alt="..." />
                  </div>
                  <div className="left_text">
                    <h2>Forgot Password</h2>
                    <p>Please enter your email address</p>
                  </div>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={validation}
                    onSubmit={(values) => {
                      // console.log(values);
                      OtpRequest(values);
                    }}
                  >
                    {({ handleChange, handleBlur }) => (
                      <FormikFrom>
                        {errorMsg && (
                          <span
                            className="error"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            {errorMsg}
                          </span>
                        )}
                        <div className="mb-3 eyeicon">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label password_text"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="email" />
                          </div>
                        </div>
                        {/* <Link to="/change-pwd"> */}
                        <button
                          type="submit"
                          className="btn btn-primary Login_button"
                        >
                          Send OTP
                        </button>
                        {/* </Link> */}
                        <div className="Forgot_password">
                          <Link to="/">Back to Login</Link>
                        </div>
                      </FormikFrom>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-6 mt-5">
                <div className="right_side">
                  <h2>Refer New Patients & Patient Journey </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <div className="main_img">
                    <img src={img2} alt="..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ForgotPassword;
