import { callAPI } from "../config/api-core";
import {unReadMsgAPIMethod} from "../methods/unreadMsg-api-method";

const unReadMsgAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    //   'content-type': 'multipart/form-data',
    Authorization: userToken && `Bearer ${token}`,
  };

  const response = await callAPI(
    unReadMsgAPIMethod,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

export const postUnreadMsg = (request) => unReadMsgAPI(request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postUnreadMsg,
};
