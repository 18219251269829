import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import Documents from "../pages/Documents";
import Mypatient from "../pages/Mypatient";
import Notification from "../pages/Notification";
import Newpatient from "../pages/Referralnewpatient";
import PublicRoute from "./PublicRoute";
import PageNotFound from "../pages/no-page";
import ExistingPatientPost from "../pages/existing-patient";
import ForgotPassword from "../pages/forgot-password";
import ChangePassword from "../pages/change-password";
import HelpCenter from "../pages/help-center";
import ContactSupport from "../pages/contact-support";
import SuggestFeature from "../pages/suggest-feature";
import TermsOfService from "../pages/terms-of-service";
import PrivacyPolicy from "../pages/privacy-policy";
import PrivacySetting from "../pages/privacy-settings";
import PrivacyPreferences from "../pages/privacy-preferences";
import ReverseReferral from "../pages/reverse-referral";

const RouteIndex = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route exact path="/documents" element={<Documents />} />
          <Route path="/mypatient" element={<Mypatient />}>
            <Route path=":patientName" element={<Mypatient />} />
          </Route>
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/newpatient" element={<Newpatient />} />
          <Route
            exact
            path="/existing-patient-post"
            element={<ExistingPatientPost />}
          />
          <Route exact path="/help-center" element={<HelpCenter />} />
          <Route exact path="/contact-support" element={<ContactSupport />} />
          <Route exact path="/suggest-a-feature" element={<SuggestFeature />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/privacy-settings" element={<PrivacySetting />} />
          <Route
            exact
            path="/privacy-preferences"
            element={<PrivacyPreferences />}
          />
          <Route exact path="/reverse-referral" element={<ReverseReferral />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};
export default RouteIndex;
