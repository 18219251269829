import * as Yup from "yup";

export const validation = Yup.object().shape({
  email: Yup.string()
    .email(" Enter valid email")
    .required(" Email field is required"),
  password: Yup.string()
    .required(" Password field is required")
    .min(6, " Password is too short - should be 6 characters minimum."),
});
