import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api/legal-api";
import Loader from "../../components/loader";
import RecordNotFound from "../../components/recorderror";

function TermsOfService() {
  const [loading, setLoading] = useState(false);
  const [termsData, setTermsData] = useState("");

  const hasMounted = useRef(false);
  // console.log("termsData", termsData);

  const fetchTermsOfService = async () => {
    setLoading(true);
    try {
      const response = await api.getTermsOfService();
      // console.log("Response", response);
      if (response && response.data.data) {
        setTermsData(response.data.data.terms_of_service);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasMounted.current) {
      fetchTermsOfService();

      hasMounted.current = true;
    }
  }, []);

  return (
    <section className="section home-page-section">
      <div className="container-fluid px-0">
        <div className="Page_content pt-0">
          <div className="row pt-0 page-top-insights mb-4">
            {loading && <Loader />}
            <div className="col-12 my-4">
              <div className="main_heading">
                <h4 className="mb-0">Terms of Service</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {termsData ? (
                <div dangerouslySetInnerHTML={{ __html: termsData }} />
              ) : (
                <RecordNotFound heading="no terms of service found" />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TermsOfService;
