import { callAPI } from "../config/api-core";
import { dashboardAPIMethods } from "../methods/dashboard-api-method";

const dashboardFetch = async () => {
  const response = await callAPI(
    dashboardAPIMethods,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

export const getDashboardAPI = () => dashboardFetch();
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDashboardAPI,
};
