import RouteIndex from "./routes";
import { createContext, useEffect, useState } from "react";
// import OneSignal from 'react-onesignal';

export const UserContext = createContext();

const App = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [lastRoute, setLastRoute] = useState("");
  const [profile, setProfile] = useState({
    profileImg: null,
    profileName: "",
    profileId: "",
    practiceName: "",
  });
  const [reverseReferralsCount, setReverseReferralsCount] = useState(null);

  return (
    <UserContext.Provider
      value={{
        userLoggedIn,
        setUserLoggedIn,
        lastRoute,
        setLastRoute,
        profile,
        setProfile,
        reverseReferralsCount,
        setReverseReferralsCount,
      }}
    >
      <RouteIndex />
    </UserContext.Provider>
  );
};

export default App;
