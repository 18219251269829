import React, { useEffect, useMemo, useState } from "react";
import "../../assets/css/document.css";
import Eyeicon from "../../assets/img/eye-icon.svg";
import Pagination from "../../components/pagination";
import { useAllDocuments } from "./custom-hook/documents-hook";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import RecordNotFound from "../../components/recorderror";
import pdfImg from "../../assets/img/pdf-img.png";
import docIcon from "../../assets/img/doc-logo.png";
import Modals from "../../components/modal";
import ToastNotification from "../../components/toast-notification";
import { useMyPatient } from "../Mypatient/custom-hook/my-patient-hook";

const Documents = () => {
  const navigate = useNavigate();
  const {
    allDocuments,
    totalDocuments,
    maxPageNumberLimit,
    minPageNumberLimit,
    paginate,
    handleNextbtn,
    handlePrevbtn,
    currentPage,
    handleKeyDown,
    handleSearch,
    loading,
    journeyList,
    changeDocumentStateAPI,
    showToast,
    setShowToast,
    toastMsg,
    messages,
  } = useAllDocuments();

  // const {messages} = useMyPatient();
  // console.log("mess", messages)

  // console.log("allDocuments", allDocuments);

  const [modalShow, setModalShow] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [extension, setExtension] = useState("");
  const [defautJourneyState, setDefaultJourneyState] = useState({});

  const handlePage = (e, name) => {
    e.preventDefault();
    navigate(`/mypatient/${name}`, { state: { showDocTabs: true } });
  };

  const handleModal = (e, img, document_extension) => {
    e.preventDefault();
    // console.log("img", img);
    // console.log("img", img);

    setImgUrl(img);
    setExtension(document_extension);
    setModalShow(true);
  };

  const value = useMemo(() => {
    let val = {};
    if (allDocuments) {
      for (const element of allDocuments) {
        let defaultValues = element.patient_journey
          ? element.patient_journey.journey_data?.id
          : "";
        val[element.id] = defaultValues;
      }
      // console.log("val", val)
      setDefaultJourneyState(val);
    }
  }, [allDocuments]);

  const handleJourneyState = (e, id, patientChannel) => {
    e.preventDefault();
    setDefaultJourneyState({ ...defautJourneyState, [id]: e.target.value });
    let param = { document_id: id, journey_id: e.target.value };
    changeDocumentStateAPI(param, id, e.target.value, patientChannel);
  };

  const pubNubValue = useMemo(() => {
    let val = {};
    if (messages) {
      for (const element of messages) {
        let defaultValues = element.message.journey_id
          ? element.message.journey_id
          : "";
        val[element.message.document_id] = defaultValues;
      }
      setDefaultJourneyState({ ...defautJourneyState, ...val });
    }
  }, [messages]);
  // useEffect(()=>{
  //   console.log("defautJourneyState", defautJourneyState)
  // },[defautJourneyState])

  return (
    <>
      <section className="section home-page-section">
        <div className="container-fluid px-0">
          {loading && <Loader />}
          {modalShow ? (
            <Modals
              show={modalShow}
              imgUrl={imgUrl}
              onHide={() => setModalShow(false)}
              docExtension={extension}
            />
          ) : null}
          {showToast ? (
            <ToastNotification
              content={toastMsg}
              setShow={setShowToast}
              show={showToast}
            />
          ) : null}
          <div className="Page_content document-page_content pt-0">
            <div className="row top_heading mb-0">
              <div className="col-md-3 my-4">
                <div className="Document_text">
                  <h6 className="mb-0">Documents</h6>
                </div>
              </div>
            </div>
            <div className="row">
              {/* {allDocuments.length > 0 ? ( */}
              <div className="col-12">
                <div className="row top_section top_section-border">
                  {/* {allDocuments.length > 0 ? ( */}
                  <>
                    <div className="col">
                      <div className="searchicon_main">
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Search Document"
                          onChange={(e) => handleSearch(e)}
                          onKeyDown={handleKeyDown}
                        />
                        <i
                          className="fa fa-search"
                          style={{
                            fontSize: "17px",
                            color: "#000",
                            position: "absolute",
                            top: "10px",
                            left: "12px",
                          }}
                        ></i>
                      </div>
                    </div>
                    <div className="col">
                      {/* {allDocuments.length > 0 ? ( */}
                      <nav>
                        <div
                          className="nav nav-pills float-end flex-nowrap"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link active me-3"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                          >
                            <i className="fa fa-list" aria-hidden="true"></i>
                          </button>
                          <button
                            className="nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                          >
                            <i className="fa fa-th" aria-hidden="true"></i>
                          </button>
                        </div>
                      </nav>
                      {/* // ) : null} */}
                    </div>
                  </>
                  {/* ) : null} */}
                </div>
                <div className="col-md-12">
                  {allDocuments.length > 0 ? (
                    <>
                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <div className="table_wrapper table-responsive">
                            <table className="table My_table tab-My_table">
                              <thead>
                                <tr className="table_heading">
                                  <th scope="col">Document</th>
                                  <th scope="col">Patient Name</th>
                                  <th scope="col">Referred by</th>
                                  <th scope="col">Procedure</th>
                                  <th scope="col">Referred on</th>
                                  <th scope="col">Journey State</th>
                                  <th className="action-th-width" scope="col">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {allDocuments &&
                                  allDocuments.map((doc, index) => {
                                    // console.log("neww", doc);
                                    const {
                                      patient_name,
                                      procedure,
                                      created_at,
                                      documents,
                                      patient_procedure,
                                      patient,
                                      patient_journey,
                                      document_download_path,
                                    } = doc;
                                    // console.log(
                                    //   documents[0].document_extension === "pdf"
                                    // );
                                    // const {journey_data} = patient_journey;
                                    return (
                                      <tr key={index}>
                                        <td className="report_img_td">
                                          <div
                                            className="report_img"
                                            // onClick={(e) =>
                                            //   handlePage(
                                            //     e,
                                            //     item.patient?.patient_name
                                            //   )
                                            // }
                                          >
                                            {doc.document_extension === "doc" ||
                                            doc.document_extension ===
                                              "docx" ? (
                                              <img src={docIcon} alt="" />
                                            ) : (
                                              // <p className="ps-2 ">
                                              //   {doc.document_extension}
                                              // </p>
                                              <img
                                                src={
                                                  doc.document_extension ===
                                                  "pdf"
                                                    ? pdfImg
                                                    : doc.document_path
                                                }
                                                alt=""
                                              />
                                            )}
                                            {/* <img
                                              src={
                                                item.document_extension ===
                                                "pdf"
                                                  ? pdfImg
                                                  : item.document_path
                                              }
                                              alt=""
                                            /> */}
                                          </div>
                                        </td>
                                        <td> {doc.patient?.patient_name}</td>
                                        <td>{doc.shared_by?.name}</td>
                                        <td>
                                          {patient?.patient_procedure?.procedure
                                            ? patient.patient_procedure
                                                .procedure
                                            : "N/A"}
                                        </td>
                                        <td>{created_at}</td>
                                        <td>
                                          {patient_journey
                                            ? patient_journey.journey_data
                                                ?.title
                                            : "N/A"}
                                        </td>
                                        {/* <td>
                                          <select
                                            className="form-select Surgery_Scheduled "
                                            // aria-label="Default select example"
                                            // id={`journey_state_dropdown_${doc.id}`}
                                            value={defautJourneyState[doc.id]}
                                            onChange={(e) =>
                                              handleJourneyState(
                                                e,
                                                doc.id,
                                                patient.activity_channel
                                              )
                                            }
                                          >
                                            <option value="">
                                              Please Select Journey State
                                            </option>
                                            {doc.patient_journey_list?.map((el, index)=>{
                                              const {journey_data} = el
                                              return(
                                                <React.Fragment key={index}>
                                                    <option value={`${journey_data.id}`}>
                                                      {journey_data.title}
                                                    </option>
                                                  </React.Fragment>
                                              )
                                            })}
                                          </select>
                                        </td> */}
                                        <td>
                                          <div className="table_icon ">
                                            <div>
                                              <Link
                                                // to={`/mypatient/${patient_name}`}
                                                to=""
                                                className=""
                                                onClick={(e) =>
                                                  handleModal(
                                                    e,
                                                    doc.document_path,
                                                    doc.document_extension
                                                  )
                                                }
                                              >
                                                <img
                                                  src={Eyeicon}
                                                  alt="eyes-icon"
                                                />
                                              </Link>
                                            </div>
                                            <div>
                                              {/* <Link to=""  onClick={(e)=>handleDownload(e,document_path)}> */}
                                              <a
                                                href={document_download_path}
                                                download
                                              >
                                                <i
                                                  className="fa fa-download"
                                                  style={{
                                                    fontSize: "17px",
                                                    color:
                                                      "rgba(38, 50, 56, 1)",
                                                  }}
                                                />
                                              </a>
                                              {/* </Link> */}
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* 
                       ///extra code */}
                        <div
                          className="tab-pane fade"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                        >
                          <div className="row mt-4">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6 sm-2">
                                  <h4 className="recent_document_text">
                                    Recents Documents
                                  </h4>
                                </div>
                                {/* <div className="col-md-6 sm-2">
                                  <div className="view_all_btn">
                                    <a href="#">View All</a>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-lg-12">
                              <div className="row gallery">
                                {allDocuments &&
                                  allDocuments.map((item, index) => {
                                    // console.log("item", item);
                                    const {
                                      patient_name,
                                      procedure,
                                      created_at,
                                      documents,
                                      patient_procedure,
                                      patient,
                                    } = item;
                                    // console.log("rrrrr", item);
                                    return (
                                      <div
                                        className="col-lg-4 col-md-4 col-sm-6 mb-5"
                                        key={index}
                                      >
                                        <div className="Recents_Documents_slip mv__Recents_Documents_slip ">
                                          <div className="slip_img">
                                            <div
                                              className="img-container"
                                              // style={{
                                              //   width: "300px",
                                              //   height: "300px",
                                              //   objectFit: "cover",
                                              //   backgroundPosition: "center",
                                              //   backgroundSize: "cover",
                                              // }}
                                              onClick={(e) =>
                                                handleModal(
                                                  e,
                                                  item.document_path,
                                                  item.document_extension
                                                )
                                              }
                                            >
                                              {item.document_extension ===
                                                "doc" ||
                                              item.document_extension ===
                                                "docx" ? (
                                                <img
                                                  src={docIcon}
                                                  alt=""
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                />
                                              ) : (
                                                // <h1>
                                                //   {item.document_extension}
                                                // </h1>
                                                <img
                                                  src={
                                                    item.document_extension ===
                                                    "pdf"
                                                      ? pdfImg
                                                      : item.document_path
                                                  }
                                                  alt=""
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                />
                                              )}
                                              {/* <img
                                                  src={
                                                    item.document_extension ===
                                                    "pdf"
                                                      ? pdfImg
                                                      : item.document_path
                                                  }
                                                  alt=""
                                                  style={{
                                                    width: "100%",
                                                    height: "100%",
                                                  }}
                                                /> */}
                                            </div>
                                          </div>
                                          <div className="slip_date">
                                            <h6>{created_at}</h6>
                                          </div>
                                          <div className="document_slip">
                                            <h6 className="Aidan_Nathan">
                                              {item.patient?.patient_name}
                                            </h6>
                                            <p className="color_grey">
                                              Referred by:{" "}
                                              {item.shared_by?.name}
                                            </p>
                                            <p>
                                              Procedure:{" "}
                                              {patient?.patient_procedure
                                                ?.procedure
                                                ? patient.patient_procedure
                                                    .procedure
                                                : "N/A"}
                                            </p>
                                            <p>
                                              Journey State:{" "}
                                              {item.patient_journey
                                                ? item.patient_journey
                                                    .journey_data?.title
                                                : "N/A"}
                                            </p>
                                            {/* <Link
                                              to={`/mypatient/${item.patient?.patient_name}`}
                                            >
                                              <button
                                                type="button"
                                                className="btn btn-primary attached_button"
                                              >
                                                Attach
                                              </button>
                                            </Link> */}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Pagination
                        totalRecord={totalDocuments}
                        maxPageNumberLimit={maxPageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        paginate={paginate}
                        handleNextbtn={handleNextbtn}
                        handlePrevbtn={handlePrevbtn}
                        currentPage={currentPage}
                      />
                    </>
                  ) : (
                    <RecordNotFound heading="documents not found" />
                  )}
                </div>
              </div>
              {/* // ) : (
              //   <RecordNotFound heading="documents not found" />
              // )} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Documents;
