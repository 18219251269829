import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { UserContext } from "../App";
import api from "../services/api/update-doctor-api";
import { useDashboard } from "../pages/Dashboard/custom-hook/dashboard-hook";
import Profileicon from "../assets/img/profile-pic.png";
import "../assets/css/profile-modal.css";
import DoctorImg from "../assets/img/DoctorImg.png";

const ProfileModal = ({ show, setShow }) => {
  const { profile } = useContext(UserContext);
  const { dashboardAPI } = useDashboard();
  const [name, setName] = useState(profile?.profileName);
  const [practiceName, setPracticeName] = useState(profile?.practiceName);
  const [image, setImage] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 24) {
      setName(value);
      setError("");
    } else {
      setError("Name should not exceed 24 characters");
    }
    // setName(e.target.value)
  };

  const handlePracticeNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 24) {
      setPracticeName(value);
      setError("");
    } else {
      setError("Name should not exceed 24 characters");
    }
    // setName(e.target.value)
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    // console.log(`Updating profile: Name - ${name}, Image - ${image}`);
    const trimmedPracticeName = practiceName?.trim() || "";
    const request = {
      name: name,
      image: image,
      ...(trimmedPracticeName.length > 0 && {
        practice_name: trimmedPracticeName,
      }),
    };
    setLoading(true);
    try {
      const response = await api.updateDoctor(request);
      // console.log("Response", response);
      if (response && response.data.status === 1) {
        // console.log("sucess", response.data.message);
        dashboardAPI();
        handleClose();
        setLoading(false);
      } else {
        // console.log("error");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className=""
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="imG-previewMv">
            <div className="avatar-upload">
              <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageUpload}
                />
                <label htmlFor="imageUpload"></label>
              </div>
              <div className="avatar-preview">
                {previewImage ? (
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${previewImage})` }}
                  ></div>
                ) : profile.profileImg ? (
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${profile?.profileImg})` }}
                  ></div>
                ) : (
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${DoctorImg})` }}
                  ></div>
                )}
              </div>
            </div>
            <div className="">
              <label className="d-block">Dr.</label>
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={handleNameChange}
                className="nameInput mx-0"
              />
              {error && (
                <div className="error" style={{ color: "red" }}>
                  {error}
                </div>
              )}
              <div className="mt-4">
                <label className="d-block">Practice name:</label>
                <input
                  type="text"
                  // placeholder="Enter your name"
                  value={practiceName}
                  onChange={handlePracticeNameChange}
                  className="nameInput mx-0"
                />
              </div>

              <Button
                variant="primary"
                type="button"
                onClick={handleProfileSubmit}
                disabled={!name}
                className="mv_Save"
              >
                {loading ? (
                  <>
                    Save Changes <Spinner animation="border" size="sm" />
                  </>
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileModal;
