import { callAPI } from "../config/api-core";
import { changeDocumentAPIMethod } from "../methods/change-document-state-api-method";


const documentStateAPI = async (request) => {
    let userToken = localStorage.getItem("token");
    let token = JSON.parse(userToken)
    let header = {
    //   'content-type': 'multipart/form-data',
      Authorization: userToken && `Bearer ${token}`,
    };
  
  const response = await callAPI(
    changeDocumentAPIMethod,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};


export const postDocumentState = (request) => documentStateAPI(request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  postDocumentState,

};
