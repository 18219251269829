import Offcanvas from "react-bootstrap/Offcanvas";
import img1 from "../assets/img/back-arrow-icon.svg";
import img2 from "../assets/img/file-upload-icon.svg";
import { Link } from "react-router-dom";
import { memo, useMemo, useState } from "react";
import api from "../services/api/update-journey-details-api";
import { useEffect } from "react";
import img3 from "../assets/img/red-close-icon.svg";
import ToastNotification from "./toast-notification";
import "../assets/css/newjourney-detail.css";
import { useMyPatient } from "../pages/Mypatient/custom-hook/my-patient-hook";

const JourneyNotes = ({
  notes,
  showJourneyDetail,
  setShowJourneyDetail,
  journeyId,
  patientId,
  newDocuments,
  setMsg,
  msg,
  showNotesToast,
  setShowNotesToast,
  messages,
  channels,
  sendMessage,
}) => {
  // console.log("newjourney component rendered");
  // console.log("journeyId", journeyId);
  // console.log("patientId", patientId);
  // console.log("notes", notes);
  // console.log("documents", documents);
  const [newNotes, setNewNotes] = useState([]);
  const [showNotes, setShowNotes] = useState(false);
  const [expandNote, setExpandNote] = useState({});
  const [lines, setLines] = useState({});
  const [character, setCharacter] = useState({});
  const [pubnubNotes, setPubnubNotes] = useState([]);
  const [pubnubExpandNote, setPubnubExpandNote] = useState({});
  const [pubnubLines, setPubnubLines] = useState({});
  const [pubnubCharacter, setPubnubCharacter] = useState({});

  const handleNotes = (e) => {
    e.preventDefault();
    setShowNotes(!showNotes);
  };

  const updateJourneyDetailsAPI = async (request) => {
    try {
      const response = await api.updateJourneyDetails(request);
      // console.log("Response", response);
      if (response && response.data.status === 1) {
        setMsg(response.data.message);
        setShowNotesToast(true);
        let publishPayload;
        if (newNotes.length > 0) {
          publishPayload = {
            title: "Add notes by doctor",
            description: response.data.data.note.message,
            created_at: response.data.data.note.created_at,
            type: "add_notes",
            notes_count: response.data.data.note.notes_count,
            journey_id: journeyId,
            shared_by: response.data.data.note.shared_by,
            patient_id: patientId,
            notes: newNotes,
          };
          sendMessage(publishPayload);
        }

        setNewNotes([]);
      } else {
        setNewNotes([]);
        setMsg(response.data.message);
        setShowNotesToast(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let oldNotes = [];
    for (const item in notes) {
      // console.log(item);
      oldNotes.push(item.notes);
    }
    let note = [...oldNotes, newNotes];
    let params = {
      patient_id: patientId,
      journey_id: journeyId,
      notes: note,
    };

    updateJourneyDetailsAPI(params);
  };

  const value = useMemo(() => {
    let arr = [];
    let pubExpand = {};
    let pubLine = {};
    let pubCharacter = {};
    if (messages) {
      for (const element of messages) {
        pubExpand[element.id] = false;
        pubLine[element.id] = 3;
        pubCharacter[element.id] = element.message.notes?.length;
        arr.unshift(element);
      }
      setPubnubNotes(arr);
      setPubnubExpandNote(pubExpand);
      setPubnubLines(pubLine);
      setPubnubCharacter(pubCharacter);
    }
  }, [messages]);

  const togglePubNubExpand = (e, id) => {
    e.preventDefault();
    setPubnubExpandNote({ ...pubnubExpandNote, [id]: !pubnubExpandNote[id] });
    let toggle = pubnubExpandNote[id] ? 3 : 10000;
    setPubnubLines({ ...lines, [id]: toggle });
  };

  const noteValues = useMemo(() => {
    let expand = {};
    let line = {};
    let character = {};
    if (notes) {
      for (const element of notes) {
        expand[element.id] = false;
        line[element.id] = 3;
        character[element.id] = element.notes?.length;
      }
      setExpandNote(expand);
      setLines(line);
      setCharacter(character);
    }
  }, [notes]);

  const toggleExpand = (e, id) => {
    e.preventDefault();
    setExpandNote({ ...expandNote, [id]: !expandNote[id] });
    let toggle = expandNote[id] ? 3 : 10000;
    setLines({ ...lines, [id]: toggle });
  };

  // console.log("notes", notes)

  return (
    <>
      <Offcanvas
        className="journey-detail"
        show={showJourneyDetail}
        onHide={() => setShowJourneyDetail(false)}
        placement="end"
        backdrop="static"
        style={{ width: "467px", borderLeft: "1px solid #f5f2f2" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <h2 className="nav nav-h2 nav-journey">
              {" "}
              <span
                className="icon journey-icon"
                onClick={() => {
                  setShowJourneyDetail(false);
                }}
              >
                <img src={img1} alt="" />{" "}
              </span>
              Journey Notes
            </h2>
            {showNotesToast ? (
              <ToastNotification
                content={msg}
                setShow={setShowNotesToast}
                show={showNotesToast}
              />
            ) : null}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* <form id="dropFileForm"> */}
          <div className="journey_page_div">
            <h6 className="mt-4 mb-3" style={{ fontWeight: "600" }}>
              Notes
            </h6>
            <div className="">
              <Link
                to=""
                className="addmorenotes"
                onClick={handleNotes}
                style={{ paddingRight: "23px", color: "red" }}
              >
                +Add
              </Link>
              {newNotes.length > 0 ? (
                <button
                  type="button"
                  className="btn  me-1 btnn-savee"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save
                </button>
              ) : null}
            </div>
          </div>

          {showNotes ? (
            <div className="form-group" style={{ width: "100%" }}>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                onChange={(e) => setNewNotes(e.target.value)}
                value={newNotes}
              />
            </div>
          ) : null}

          <div className="noted-div">
            {pubnubNotes.length > 0
              ? pubnubNotes.map((item, index) => {
                  return item.channel === channels[0] &&
                    item.message.patient_id == patientId &&
                    item.message.journey_id == journeyId &&
                    item.message.type === "add_notes" ? (
                    <div className="noted-card" key={index}>
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: pubnubLines[item.id],
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.message.notes}
                      </p>
                      <h5>
                        {" "}
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            color: "#33B0CE",
                          }}
                        >
                          Shared By:
                        </span>{" "}
                        {item.message.shared_by}
                      </h5>
                      {pubnubCharacter[item.id] > 150 && (
                        <button
                          className="mv__reader_btn"
                          onClick={(e) => togglePubNubExpand(e, item.id)}
                        >
                          {pubnubExpandNote[item.id]
                            ? "Show Less"
                            : "Show More"}
                        </button>
                      )}
                    </div>
                  ) : null;
                })
              : null}
            {notes.length > 0
              ? notes.map((item, index) => {
                  return (
                    <div className="noted-card" key={index}>
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: lines[item.id],
                          // WebkitLineClamp: character[item.id],
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.notes}
                      </p>
                      <h5>
                        {" "}
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            color: "#33B0CE",
                          }}
                        >
                          Shared By:
                        </span>{" "}
                        {item.shared_by.name}
                      </h5>
                      {/* {lines[item.id] < 10000 ? (
                        <button onClick={(e) => toggleExpand(e, item.id)}>
                          {expandNote[item.id] ? "Show Less" : "Show More"}
                        </button>
                      ) : (
                        <button onClick={(e) => toggleExpand(e, item.id)}>
                          {expandNote[item.id] ? "Show Less" : "Show More"}
                        </button>
                      )} */}
                      {character[item.id] > 150 && (
                        <button
                          className="mv__reader_btn"
                          onClick={(e) => toggleExpand(e, item.id)}
                        >
                          {expandNote[item.id] ? "Show Less" : "Show More"}
                        </button>
                      )}
                    </div>
                  );
                })
              : null}
          </div>

          {/* </form> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default memo(JourneyNotes);
