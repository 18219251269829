import React, { useState } from "react";
import ToastNotification from "../../components/toast-notification";
import api from "../../services/api/help-api";
import "../contact-support/contact-support.css";
// import "./contact-support.css";

function SuggestFeature() {
  const [suggestedFeature, setSuggestedFeature] = useState("");
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSuggestedFeatureChange = (e) => {
    setSuggestedFeature(e.target.value);
  };

  const handleReset = () => {
    setSuggestedFeature("");
  };

  const suggestFeatureAPI = async (request) => {
    setLoading(true);
    try {
      const response = await api.suggestFeature(request);
      if (response && response.data.status === 1) {
        setMsg(response.data.message);
        setShow(true);
      } else {
        setMsg(response.data.message);
        setShow(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      handleReset();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const trimmedSuggestedFeature = suggestedFeature.trim();
    const request = {
      description: trimmedSuggestedFeature,
    };

    suggestFeatureAPI(request);
  };

  return (
    <section className="section home-page-section">
      <div className="container-fluid px-0 px-0 py-4">
        <div className="container">
          <div className="row">
            {show ? (
              <ToastNotification setShow={setShow} show={show} content={msg} />
            ) : null}
            <div className="col-md-8 mx-auto">
              <div className="card p-3 bg-white border border-0 contact_card">
                <div className="main_heading">
                  <h4 className="mb-4 text-center heading__h4">
                    Suggest a feature
                  </h4>
                </div>

                <form onSubmit={handleSubmit}>
                  <label htmlFor="problemOverview">Enter your idea:</label>

                  <textarea
                    className="form-control"
                    rows="4"
                    value={suggestedFeature}
                    onChange={handleSuggestedFeatureChange}
                  ></textarea>

                  <div>
                    <p className="msg_error">
                      We’re always working to improve the experience of you and
                      your practice, so we’d love to hear what could be done
                      better.
                    </p>
                  </div>

                  <div className="col-md-12 text-center mt-4 action__btn">
                    <button
                      type="button"
                      className="btn btn-save-outline"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn  ms-3 btn-save"
                      disabled={!suggestedFeature.trim()}
                    >
                      {loading ? (
                        <>
                          Submit <i className="fa fa-spinner fa-spin" />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SuggestFeature;
