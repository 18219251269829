import { useEffect, useRef, useState } from "react";
import api from "../../../services/api/all-documents-api";
import { useMyPatient } from "../../Mypatient/custom-hook/my-patient-hook";
import apis from "../../../services/api/new-journey-api";
import apiss from "../../../services/api/change-journey-state-api";
import { usePubNub } from "pubnub-react";
import { useLogin } from "../../login/custom-hook/login-hook";

export const useAllDocuments = () => {
  const [allDocuments, setAllDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [hitApi, setHitApi] = useState(false);
  const [journeyList, setJourneyList] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");

  /*pubnub state */
  const pubnub = usePubNub();
  const [channels, setChannels] = useState([]);
  const [messages, addMessage] = useState([]);
  const [messageToPubnub, setMessageToPubnub] = useState("");

  const hasMounted = useRef(false);
  const { handleError } = useLogin();

  useEffect(() => {
    if (allDocuments) {
      let arr = [];
      for (const item of allDocuments) {
        arr.push(item.patient?.activity_channel);
      }
      setChannels(arr);
    }
  }, [allDocuments]);

  useEffect(() => {
    if (!hasMounted.current) {
      allDocumentsAPI({ page_no: 1, limit: 6 });
      getNewJourneyListAPI();
      hasMounted.current = true;
    }
  }, []);

  const getNewJourneyListAPI = async () => {
    setLoading(true);
    // console.log("request", request);
    try {
      const response = await apis.newJourneyList();
      // console.log("Response", response);
      if (response && response.data.data.length !== 0) {
        setJourneyList(response.data.data.journey_list);
        // setActivity(response.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
        // console.log("journey error");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  /* change document state */
  const changeDocumentStateAPI = async (
    request,
    docId,
    journey_id,
    patientChannel
  ) => {
    // setLoading(true);
    // console.log("request", request);
    // console.log("docId", docId);
    // console.log("journey_id", journey_id);
    // console.log("patientChannel", patientChannel);
    try {
      const response = await apiss.postDocumentState(request);
      // console.log("Response", response);
      if (response && response.data.status == 1) {
        // console.log("hii status changed");
        // console.log("response.data.data", response.data.data);
        setToastMsg(response.data.message);
        setShowToast(true);
        // let publishPayload;
        // if (newNotes.length > 0) {
        let publishPayload = {
          title: "Change document journey state by Doctor",
          description: response.data.data.message,
          created_at: response.data.data.created_at,
          type: "change_document_journey_state",
          document_id: docId,
          journey_id: journey_id,
          patient_id: response.data.data.refer_patient_id,
        };
        sendMessage(publishPayload, patientChannel);
      } else {
        setToastMsg("Please Select Journey State");
        setShowToast(true);
        // setLoading(false);
        // console.log("journey error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const allDocumentsAPI = async (request) => {
    setLoading(true);
    try {
      const response = await api.allDocumentsList(request);
      // console.log("Response", response);
      if (response && response.data.data) {
        setAllDocuments(response.data.data.documents_list);
        setTotalDocuments(response.data.data.total_documents);
        setLoading(false);
      } else {
      }
    } catch (err) {
      console.log(err);
      if (err.response.status == 401) {
        handleError();
        setLoading(false);
      }
    }
  };

  const paginate = async (pageNumber) => {
    setCurrentPage(pageNumber);
    hitApi
      ? await allDocumentsAPI({
          page_no: pageNumber,
          keyword: searchTerm,
          limit: 6,
        })
      : await allDocumentsAPI({ page_no: pageNumber, limit: 6 });
  };

  const handleNextbtn = async () => {
    setCurrentPage(currentPage + 1);
    hitApi
      ? await allDocumentsAPI({
          page_no: currentPage + 1,
          keyword: searchTerm,
          limit: 6,
        })
      : await allDocumentsAPI({ page_no: currentPage + 1, limit: 6 });

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = async () => {
    setCurrentPage(currentPage - 1);
    hitApi
      ? await allDocumentsAPI({
          page_no: currentPage - 1,
          keyword: searchTerm,
          limit: 6,
        })
      : await allDocumentsAPI({ page_no: currentPage - 1, limit: 6 });

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (hitApi) {
      if (e.target.value.length === 0) {
        setHitApi(false);
        allDocumentsAPI({ page_no: 1, limit: 6 });
        // myPatientAPI();
      }
    }
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" ||
      e.key === "Go" ||
      e.key === "Search" ||
      e.key === "NumpadEnter" ||
      e.key === "Return"
    ) {
      // console.log("search", searchTerm);
      if (searchTerm.length > 0) {
        setHitApi(true);
        allDocumentsAPI({ page_no: 1, keyword: searchTerm, limit: 6 });
        // myPatientAPI({ keyword: searchTerm });
      }
    }
  };

  /*pubnub implementation */
  const handleMessage = (event) => {
    // console.log("event", event);
    const message = event;
    // console.log("listen", message);
    // console.log(typeof `#${channels[0]}`);
    // console.log(message.message.type === "change_status");

    if (message.message.type === "change_document_journey_state") {
      addMessage((msg) => [...msg, message]);
    }
  };

  const sendMessage = (message, patientChannel) => {
    // console.log("sendmessage");
    // console.log("pubnubpayload", message);
    if (message) {
      pubnub
        .publish({ channel: patientChannel, message })
        .then(() => setMessageToPubnub(""));
    }
  };

  useEffect(() => {
    const listenerParams = { message: handleMessage };
    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channels]);
  /*END */

  return {
    // allDocumentsAPI,
    allDocuments,
    totalDocuments,
    maxPageNumberLimit,
    minPageNumberLimit,
    paginate,
    handleNextbtn,
    handlePrevbtn,
    currentPage,
    handleSearch,
    handleKeyDown,
    loading,
    journeyList,
    changeDocumentStateAPI,
    showToast,
    setShowToast,
    toastMsg,
    messages,
  };
};
