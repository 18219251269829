
const InputField = ({ id, ariaDescribedby, label, name, type, onChange, htmlFor, labelClassName, inputClassName, onBlur, placeholder  }) => (
  <div className="form-group">
    {label && <label htmlFor={htmlFor} className={labelClassName}>{label}</label>}
    <input
      type={type}
      // value={value}
      name={name}
      className={inputClassName}
			id={id}
			aria-describedby={ariaDescribedby}
      placeholder={placeholder}
      onChange={onChange}
			onBlur={onBlur}
    />
  </div>
);

export default InputField;
