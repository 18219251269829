import React, { useEffect, useRef, useState } from "react";
import api from "../../services/api/legal-api";
import Loader from "../../components/loader";
import RecordNotFound from "../../components/recorderror";

function PrivacySetting() {
  const [loading, setLoading] = useState(false);
  const [privacySettingData, setPrivacySettingData] = useState("");

  const hasMounted = useRef(false);
  // console.log("privacySettingData", privacySettingData);

  const fetchPrivacySetting = async () => {
    setLoading(true);
    try {
      const response = await api.getPrivacySettings();
      // console.log("Response", response);
      if (response && response.data.data) {
        setPrivacySettingData(response.data.data.privacy_settings);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!hasMounted.current) {
      fetchPrivacySetting();

      hasMounted.current = true;
    }
  }, []);

  return (
    <section className="section home-page-section">
      <div className="container-fluid px-0">
        <div className="Page_content pt-0">
          <div className="row pt-0 page-top-insights mb-4">
            {loading && <Loader />}
            <div className="col-12 my-4">
              <div className="main_heading">
                <h4 className="mb-0">Privacy Setting</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {privacySettingData ? (
                <div dangerouslySetInnerHTML={{ __html: privacySettingData }} />
              ) : (
                <RecordNotFound heading="no privacy setting found" />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacySetting;
