import React, { useEffect, useRef, useState } from "react";
import "../assets/css/ticker.css";
import api from "../services/api/legal-api";
import { useNavigate } from "react-router-dom";

function NewFooter() {
  const [tickerContent, setTickerContent] = useState(null);
  const hasMounted = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasMounted.current) {
      fetchTickerContent();
      hasMounted.current = true;
    }
  }, []);

  const fetchTickerContent = async () => {
    try {
      const response = await api.getTickerAPI();

      if (response && response.data.data) {
        setTickerContent(response.data.data.news_ticker);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const linkToRoute = {
    dashboard: "/dashboard",
    my_patient: "/mypatient",
    new_patient: "/newpatient",
    documents: "/documents",
    help_center: "/help-center",
    contact_support: "/contact-support",
    suggest_a_feature: "/suggest-a-feature",
    terms_of_services: "/terms-of-service",
    privacy_policy: "/privacy-policy",
    privacy_settings: "/privacy-settings",
    privacy_preferences: "/privacy-preferences",
    existing_patient_post: "/existing-patient-post",
  };

  const handleClick = () => {
    if (tickerContent?.redirection_mode === "external") {
      window.open(tickerContent.link, "_blank");
    }
    if (tickerContent?.redirection_mode === "internal") {
      const routePath = linkToRoute[tickerContent.internal_link];
      if (routePath) {
        navigate(routePath);
      }
    }
  };

  return (
    <div className="ticker">
      <p className="moving-text" onClick={handleClick}>
        {tickerContent?.heading}
        <br />
        {tickerContent?.sub_heading}
      </p>
    </div>
  );
}

export default NewFooter;
