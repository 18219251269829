import React, { useState } from "react";
import ToastNotification from "../../components/toast-notification";
import api from "../../services/api/help-api";
import "./contact-support.css";

function ContactSupport() {
  const [emailChecked, setEmailChecked] = useState(false);
  const [messageChecked, setMessageChecked] = useState(false);
  const [problemOverview, setProblemOverview] = useState("");
  const [show, setShow] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = () => {
    setEmailChecked(!emailChecked);
    setMessageChecked(false);
  };

  const handleMessageChange = () => {
    setMessageChecked(!messageChecked);
    setEmailChecked(false);
  };

  const handleProblemOverviewChange = (e) => {
    setProblemOverview(e.target.value);
  };

  const handleReset = () => {
    setEmailChecked(false);
    setMessageChecked(false);
    setProblemOverview("");
  };

  const contactSupportAPI = async (request) => {
    setLoading(true);
    try {
      const response = await api.contactSupport(request);

      if (response && response.data.status === 1) {
        setMsg(response.data.message);
        setShow(true);
      } else {
        setMsg(response.data.message);
        setShow(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      handleReset();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedProblemOverview = problemOverview.trim();
    const request = {
      contact_type: emailChecked ? "email" : "message",
      question: trimmedProblemOverview,
    };
    contactSupportAPI(request);
  };

  const isSubmitDisabled = !(emailChecked || messageChecked);

  return (
    <section className="section home-page-section ">
      <div className="container-fluid px-0 py-4 ">
        <div className="container">
          <div className="row">
            {show ? (
              <ToastNotification setShow={setShow} show={show} content={msg} />
            ) : null}

            <div className="col-md-8 mx-auto">
              <div className="card p-3 bg-white border border-0 contact_card">
                <div className="main_heading">
                  <h4 className="mb-4 text-center heading__h4">
                    Contact Support
                  </h4>
                </div>
                <form onSubmit={handleSubmit}>
                  <label className="d-block">Response Preference:</label>

                  <div className="form-group">
                    <div className="form-check form-check-inline d-inline-flex">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="emailCheckbox"
                        checked={emailChecked}
                        onChange={handleEmailChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="emailCheckbox"
                      >
                        Email
                      </label>
                    </div>
                    <div className="form-check form-check-inline d-inline-flex">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="messageCheckbox"
                        checked={messageChecked}
                        onChange={handleMessageChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="messageCheckbox"
                      >
                        Message
                      </label>
                    </div>
                  </div>

                  <label htmlFor="problemOverview">Problem Overview:</label>

                  <textarea
                    className="form-control"
                    id="problemOverview"
                    rows="4"
                    value={problemOverview}
                    onChange={handleProblemOverviewChange}
                  ></textarea>

                  {emailChecked || messageChecked ? (
                    <div>
                      <p className="msg_error">
                        {`We will ${
                          emailChecked
                            ? "email"
                            : messageChecked
                            ? "message"
                            : ""
                        } you as soon as possible to resolve these issues`}
                      </p>
                    </div>
                  ) : null}
                  <div className="col-md-12 text-center mt-4 action__btn">
                    <button
                      type="button"
                      className="btn btn-save-outline"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="btn  ms-3 btn-save"
                      disabled={!problemOverview.trim() || isSubmitDisabled}
                    >
                      {loading ? (
                        <>
                          Submit <i className="fa fa-spinner fa-spin" />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSupport;
