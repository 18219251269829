import { useEffect, useRef, useState } from "react";
import api from "../../../services/api/referral-patients-list-api";
import apis from "../../../services/api/journey-list-api";
import apiss from "../../../services/api/activity-api";
import apisss from "../../../services/api/change-journey-state-api";
import apissss from "../../../services/api/new-journey-api";
import api6 from "../../../services/api/document-api";
import api7 from "../../../services/api/patient-journey-state-list-api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePubNub } from "pubnub-react";
import { useSearchParams } from "react-router-dom";
import { useLogin } from "../../login/custom-hook/login-hook";

export const useMyPatient = () => {
  const [myPatientDetails, setMyPatientDetails] = useState([]);
  const [myPatientPage, setMyPatientPage] = useState(1);
  const [myPatientPageLoading, setMyPatientPageloading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [document, setDocument] = useState([]);
  const [loading, setLoading] = useState(false);
  const [patientFromDoc, setpatientFromDoc] = useState([]);
  const [showDocTab, setShowDocTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hitApi, setHitApi] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState([]);
  const [PatientJourney, setPatientJourney] = useState([]);
  const [activity, setActivity] = useState([]);
  const [journeyList, setJourneyList] = useState([]); ///////////////////////////////////////////////////
  const [tabType, setTabType] = useState("");
  const [showJourneyStateToast, setShowJourneyStateToast] = useState(false);
  const [journeyStateToastMsg, setJourneyStateToastMsg] = useState("");
  const [documentSearchTerm, setDocumentSearchTerm] = useState("");
  const scrollRef = useRef(null);
  const { handleError } = useLogin();

  /*pubnub state */
  const pubnub = usePubNub();
  const [channels, setChannels] = useState([]);
  const [messages, addMessage] = useState([]);
  const [messageToPubnub, setMessageToPubnub] = useState("");
  const [patientChannel, setPatientChannel] = useState([]);

  const location = useLocation();
  const param = useParams();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const hasMounted = useRef(false);
  // const searchParam = new URLSearchParams(location.search);
  // console.log("searchParam", searchParam)

  /*handle tabs */
  // const handleTab=(e, type)=>{
  //   e.preventDefault();
  //   console.log("type", type)
  //   setTabType(type)
  // }

  useEffect(() => {
    let paramUndefined = param.patientName;
    if (paramUndefined == undefined) {
      setSelectedPatient(myPatientDetails[0]);
    }
  }, [param]);

  useEffect(() => {
    if (!hasMounted.current || myPatientPage > 1) {
      setMyPatientPageloading(true);
      myPatientAPI({ page: myPatientPage });
      hasMounted.current = true;
    }
  }, [myPatientPage]);

  // useEffect(()=>{
  //   console.log("myPatientPage", myPatientPage)
  // },[myPatientPage])

  // useEffect(() => {
  //   getNewJourneyListAPI();
  // }, []);

  // useEffect(() => {
  //   // myPatientAPI();
  //   getNewJourneyListAPI();        ////////////////////////////////////////////////////////////////////////////////////////////
  // }, []);

  /* CODE FOR PAGINATION ON SCROLL OF REFERAL PATIENT LIST */
  const handleScroll = () => {
    const { current } = scrollRef;
    const { scrollHeight, clientHeight, scrollTop } = current;
    if (
      !(myPatientDetails.length < myPatientPage * 20 || myPatientPageLoading) &&
      scrollHeight - clientHeight - scrollTop <= 5
    ) {
      setMyPatientPage(myPatientPage + 1);
    }
    // if (myPatientDetails.length < myPatientPage * 20 || myPatientPageLoading) {
    //   return null;
    // } else {
    //   setMyPatientPage(myPatientPage + 1);
    // }
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
    // window.removeEventListener("scroll", handleScroll);
  }, [myPatientDetails, myPatientPage, myPatientPageLoading]);

  /*CODE FOR SELECTED PAATIENT JOURNEY, ACTIVITY, DOCUMENTS*/
  useEffect(() => {
    // console.log("selectedPatient function is calling")
    if (selectedPatient?.id) {
      // setLoading(true);
      let url = `?referral=${selectedPatient.id}`;
      localStorage.setItem("url", url);
      // setSearchParams(url)
      window.history.pushState("", "", url);
      // window.history.replaceState("", "", url);

      // console.log("selectedPatient function after id set is calling")
      journeyAPI({ patient_id: selectedPatient.id });
      addMessage([]);
      setDocumentSearchTerm("");
      setChannels([selectedPatient.activity_channel]);
      setPatientChannel([selectedPatient.chat_channel]);
      setActivity([]);
      activityAPI(selectedPatient.id);
      setDocument([]);
      documentAPI({ patient_id: selectedPatient.id });
      patientJourneyStateAPI({ refer_patient_id: selectedPatient.id });
      // setLoading(false)
    }
  }, [selectedPatient]);
  /*END */

  useEffect(() => {
    let show = location.state?.showDocTabs;
    if (Object.keys(param).length > 0 && hitApi === false) {
      handleDocs(param.patientName);
      setShowDocTab(show);
      // console.log("handleDoc effect is calling")
    }
  }, [myPatientDetails, param]);

  const handleDocs = (patient) => {
    // console.log("patient", patient)
    // console.log("myPatientDetails", myPatientDetails)
    let doc = myPatientDetails.find((item) => item.patient_name == patient);
    // console.log("doc", doc);
    setSelectedPatient(doc);
    // setDocument(doc?.documents);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (hitApi) {
      if (e.target.value.length === 0) {
        setHitApi(false);
        myPatientAPI();
        if (Object.keys(param).length > 0) {
          navigate("/mypatient");
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" ||
      e.key === "Go" ||
      e.key === "Search" ||
      e.key === "NumpadEnter" ||
      e.key === "Return"
    ) {
      // console.log("search", searchTerm);
      if (searchTerm.length > 0) {
        setHitApi(true);
        myPatientAPI({ keyword: searchTerm });
      }
    }
  };

  const handleBack = () => {
    // console.log("hi i m back");
    setShowDocTab(false);
    navigate("/mypatient");
  };

  /* SEARCHING USING DEBOUNCE */
  // const handleKeyUp = (e) => {
  //   setSearchTerm(e.target.value);
  //   if (hitApi) {
  //     if (e.target.value.length === 0) {
  //       setHitApi(false);
  //       myPatientAPI();
  //       if (Object.keys(param).length > 0) {
  //         navigate("/mypatient");
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   let timerId;
  //   if (searchTerm) {
  //     timerId = setTimeout(() => {
  //       setHitApi(true);
  //       myPatientAPI({ keyword: searchTerm });
  //     }, 500);
  //   }

  //   return()=> {
  //     clearTimeout(timerId);
  //   }
  // }, [searchTerm]);
  /* END */

  /*CODE FOR MY REFERRAL PATIENT LIST API */
  // const myPatientAPI = async (request) => {
  //   // console.log("mypatient api is calling")
  //   setLoading(true);
  //   try {
  //     const response = await api.referPatientsList(request);
  //     console.log("Response", response);

  //     if (response && response.data.data.patients_list.data.length !== 0) {
  //       setNotFound(false);
  //       setMyPatientDetails([...myPatientDetails, ...response.data.data.patients_list.data]);
  //       // setMyPatientDetails(response.data.data.patients_list.data);
  //       // setDocument(response.data.data.patients_list.data[0].documents);
  //       setDocument(myPatientDetails[0] ? myPatientDetails[0].document : response.data.data.patients_list.data[0].documents);
  //       setMyPatientPageloading(false);
  //       setHasMore(response.data.data.patients_list.data.length > 0);
  //       if (Object.keys(param).length === 0) {
  //         setSelectedPatient( myPatientDetails[0] ? myPatientDetails[0] : response.data.data.patients_list.data[0]);
  //         // setSelectedPatient(response.data.data.patients_list.data[0]);
  //       }
  //     } else {
  //       // setMyPatientPageloading(false);
  //       setNotFound(true);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const myPatientAPI = async (request) => {
    // console.log("mypatient api is calling")
    setLoading(true);
    try {
      const response = await api.referPatientsList(request);
      // console.log("Response", response);

      if (response && response.data.data.patients_list.data.length !== 0) {
        setNotFound(false);
        if (myPatientPage > 1 && !searchTerm) {
          setMyPatientDetails([
            ...myPatientDetails,
            ...response.data.data.patients_list.data,
          ]);
          // setDocument(
          //   myPatientDetails[0]
          //     ? myPatientDetails[0].document
          //     : response.data.data.patients_list.data[0].documents
          // );
          setMyPatientPageloading(false);
          setHasMore(response.data.data.patients_list.data.length > 0);
          if (Object.keys(param).length === 0) {
            setSelectedPatient(
              myPatientDetails[0]
                ? myPatientDetails[0]
                : response.data.data.patients_list.data[0]
            );
            // setSelectedPatient(response.data.data.patients_list.data[0]);
          }
        } else {
          setMyPatientDetails(response.data.data.patients_list.data);
          // setDocument(response.data.data.patients_list.data[0].documents);
          setMyPatientPageloading(false);
          // setHasMore(response.data.data.patients_list.data.length > 0);
          if (Object.keys(param).length === 0) {
            setSelectedPatient(response.data.data.patients_list.data[0]);
            // setSelectedPatient(response.data.data.patients_list.data[0]);
          }
        }
      } else {
        // setMyPatientPageloading(false);
        setNotFound(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response.status == 401) {
        handleError();
        setLoading(false);
      }
    }
  };
  /*END */

  /*CODE FOR DOCUMENT API */
  const documentAPI = async (request) => {
    setLoading(true);
    try {
      const response = await api6.documentsList(request);
      // console.log("Response", response);
      if (response && response.data.data.length !== 0) {
        setDocument(response.data.data);
        // setPatientJourney(response.data.data.patient_journey_list);
      } else {
        // setLoading(false);
        // console.log("journey error");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  /*END */

  /*CODE FOR JOURNEY LISTING API */
  const journeyAPI = async (request) => {
    setLoading(true);
    // console.log("journey api is calling")
    // console.log("request", request);
    try {
      setPatientJourney([]);
      const response = await apis.journeyList(request);
      // console.log("Response", response);
      if (response && response.data.data.length !== 0) {
        setPatientJourney(response.data.data.patient_journey_list);
      } else {
        // setLoading(false);
        // console.log("journey error");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  /*END */

  /*CODE FOR ACTIVITY API */
  const activityAPI = async (request) => {
    // console.log("activity api is calling")
    setLoading(true);
    // console.log("request", request);
    try {
      const response = await apiss.getActivityAPI(request);
      // console.log("Response", response);
      if (response && response.data.data.length !== 0) {
        setActivity(response.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
        // console.log("journey error");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  /*END */

  /*pubnub implementation */
  const handleMessage = (event) => {
    // console.log("event", event);
    const message = event;
    // console.log("listen", message);
    // console.log(typeof `#${channels[0]}`);
    // console.log(message.message.type === "change_status");

    if (
      message.message.type === "change_status" ||
      message.message.type === "add_notes" ||
      message.message.type === "add_documents" ||
      message.message.type === "change_document_journey_state"
      // message.message.type === "change_status"
    ) {
      addMessage((msg) => [...msg, message]);
      // addMessage([ message]);
      // window.document.querySelector("[name='journey_id_"+message.message.journey_id+"'][value='"+message.message.status+"']").checked = true
    }
  };

  const sendMessage = (message) => {
    // console.log("sendmessage");
    // console.log("pubnubpayload", message);
    if (message) {
      pubnub
        .publish({ channel: channels[0], message })
        .then(() => setMessageToPubnub(""));
    }
  };

  useEffect(() => {
    const listenerParams = { message: handleMessage };
    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels });
    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channels]);
  /*END */

  /* change document state */
  const changeDocumentStateAPI = async (request, docId, journey_id) => {
    // setLoading(true);
    try {
      const response = await apisss.postDocumentState(request);
      // console.log("Response", response);
      if (response && response.data.status == 1) {
        setJourneyStateToastMsg(response.data.message);
        setShowJourneyStateToast(true);
        let publishPayload = {
          title: "Change document journey state by Doctor",
          description: response.data.data.message,
          created_at: response.data.data.created_at,
          type: "change_document_journey_state",
          document_id: docId,
          journey_id: journey_id,
          patient_id: response.data.data.refer_patient_id,
        };
        sendMessage(publishPayload);
        journeyAPI({ patient_id: response.data.data.refer_patient_id });
      } else {
        setShowJourneyStateToast(true);
        setJourneyStateToastMsg("Please Select Journey State");
        // setLoading(false);
        // console.log("journey error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  /*journey listing FOR SELECT*/
  const getNewJourneyListAPI = async () => {
    setLoading(true);
    // console.log("request", request);
    try {
      const response = await apissss.newJourneyList();
      // console.log("Response", response);
      if (response && response.data.data.length !== 0) {
        setJourneyList(response.data.data.journey_list);
        // setActivity(response.data.data);
        // setLoading(false);
      } else {
        // setLoading(false);
        // console.log("journey error");
      }
    } catch (err) {
      console.log(err);
    }
  };
  /*END */

  /*CODE FOR PATIENT JOURNEY STATE LIST API */
  const patientJourneyStateAPI = async (request) => {
    setLoading(true);
    try {
      const response = await api7.patientJourneyStateList(request);
      // console.log("Response", response);
      if (response && response.data.data.length !== 0) {
        setJourneyList(response.data.data);
      } else {
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  /*END */

  return {
    myPatientDetails,
    handleDocs,
    document,
    loading,
    patientFromDoc,
    showDocTab,
    myPatientAPI,
    handleSearch,
    handleKeyDown,
    notFound,
    hitApi,
    setHitApi,
    notFound,
    selectedPatient,
    setSelectedPatient,
    handleBack,
    PatientJourney,
    messages,
    journeyAPI,
    activity,
    channels,
    sendMessage,
    changeDocumentStateAPI,
    journeyList,
    getNewJourneyListAPI,
    showJourneyStateToast,
    setShowJourneyStateToast,
    journeyStateToastMsg,
    documentSearchTerm,
    setDocumentSearchTerm,
    myPatientPageLoading,
    hasMore,
    myPatientAPI,
    scrollRef,
    searchTerm,
    patientChannel,
    setPatientJourney,
  };
};
