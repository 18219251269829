import "../assets/css/recorderror.css";
import img from "../assets/img/no-record-outline.svg";

const RecordNotFound = ({ heading, classNames }) => {
  return (
    <>
      <div className={classNames ? classNames : "record_nfound"} >
        <div className="record_nfound-content">
          <div className="record_nfound-image">
            <img src={img} alt="404 error" />
          </div>

          <h4>{heading}</h4>
          <p>
            The data you are looking for dosen't exist any record.
            <br />
            {/* Go back, or head over the <b>example.com</b> to choose a new
            direction. */}
          </p>
        </div>
      </div>
    </>
  );
};
export default RecordNotFound;
