import { useContext, useEffect, useLayoutEffect, useState } from "react";
import api from "../../../services/api/login-api";
import apis from "../../../services/api/logout-api";
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
  const { setUserLoggedIn, setLastRoute } = useContext(UserContext);
  // console.log("userhook", userLoggedIn)
  const [isRevaled, setIsRevaled] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [loginUser, setLoginUser] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  // const [userLoggedIn, setUserLoggedIn] = useState(false)

  // useEffect(() => {
  //   let userFromLocalStorage = localStorage.getItem("token");
  //   if (userFromLocalStorage && JSON.parse(userFromLocalStorage)) {
  //     //  console.log("userinlocal", user)
  //     setUserLoggedIn(true);
  //   }
  // }, []);

  useLayoutEffect(() => {
    let userFromLocalStorage = localStorage.getItem("token");
    if (userFromLocalStorage && JSON.parse(userFromLocalStorage)) {
      //  console.log("userinlocal", user)
      setUserLoggedIn(true);
    }
  }, []);

  const hideshow = () => {
    // console.log("hi")
    setIsRevaled(!isRevaled);
  };

  const loginUserAPI = async (request) => {
    // console.log("ravy", request);
    setLoading(true);
    try {
      const response = await api.auth(request);
      // console.log("Response", response);
      if (response && response.data.data?.token) {
        localStorage.setItem("token", JSON.stringify(response.data.data.token));
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        setUserLoggedIn(true);
        setLoading(false);
        navigate("/dashboard");
      } else {
        // console.log("error ocuured");
        setLoginError(response.data.message);
        setLoading(false);
      }

      // setLoginUser(response.data.data.token)
    } catch (err) {
      console.log(err);
      // console.log(err.response.data.message);
      // setLoginError(err.response.data.data.error);
    }
  };

  const logoutUserAPI = async (request) => {
    // console.log("ravy", request);
    setLoading(true);
    try {
      const response = await apis.logoutApi(request);
      // console.log("Response", response);
      if (response && response.data.status == 1) {
        localStorage.clear();
        setUserLoggedIn(false);
        setLastRoute("");
        setLoading(false);
      } else {
        // console.log("error ocuured");
        setLoginError(response.data.message);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogout = () => {
    const param = {
      device_type: "web",
    };
    logoutUserAPI(param);
  };

  const handleError = () => {
    localStorage.clear();
    setUserLoggedIn(false);
    setLastRoute("");
    setLoading(false);
  };

  return {
    isRevaled,
    hideshow,
    loginUserAPI,
    loginUser,
    handleLogout,
    loginError,
    loading,
    setLoading,
    handleError,
    // userLoggedIn
  };
};
